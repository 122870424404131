import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, Chip, MenuItem, Avatar } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { getAllPaises } from "../../redux/slices/paises";
import { getAllBusinessName } from "../../redux/slices/businessName";
import { getAllRegions, getCommuneByRegion } from "../../redux/slices/regions";
import { validateRUT } from "validar-rut";
import axiosInstance from "../../utils/axiosInstance";
import { createNewClient, updateClient } from "../../redux/slices/clients";
import axios from "axios";
import enviroments from "../../utils/enviroments";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function NewClientForm({ clients, open_edit, data_edit, close_edit }) {
  const [open, setOpen] = React.useState(false);
  const { currentUser } = useSelector((state) => state.auth);
  const isViewMode = currentUser.action_mode === "view";
  const { data: businessData } = useSelector((state) => state.businessName);
  const { paises } = useSelector((state) => state.paises);
  const { data: regionData, comunas } = useSelector((state) => state.regions);

  const [url, setUrl] = React.useState();
  const [image, setImage] = React.useState(null);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const handleClose = () => {
    if (data_edit) {
      close_edit({ status: false, data: null });
    }
    setOpen(false);
    setImage(null);
    setUrl("");
    reset({
      nombre: "",
      tipo_razon_social: "",
      rut: "",
      documento_identidad: "",
      region: "",
      comuna: "",
      direccion: "",
      mail_contacto: "",
      hijo: "",
      padre: "",
      nombre_pago: "",
      correo_pago: "",
      tipo_empresa: "",
    });
  };

  const handleChangeRut = (event) => {
    let value = event.target.value.trim().replace(/[^\dkK]/g, ""); // Elimina caracteres no válidos
    let formattedValue = "";

    if (value.length > 1) {
      formattedValue = value.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "-" + value.slice(-1);
    } else {
      formattedValue = value;
    }

    return formattedValue;
  };

  const countryWatcher = watch("pais_id");
  const regionWatcher = watch("region");
  const sonWatcher = watch("hijo");
  const parentWatcher = watch("padre");

  const isChilean = paises.find((el) => el.id === countryWatcher)?.name === "Chile";

  const validateDu = async (val) => {
    const { data } = await axiosInstance.get(
      `/cliente/rut/validate?id=${data_edit?.id || null}&parentsRut=${parentWatcher || null}`
    );
    if (data?.arr) {
      return data.arr.includes(val) ? "El rut se encuentra en uso" : false;
    } else {
      return false;
    }
  };

  const handle_submit = async (values) => {
    if (image !== null) {
      const formData = new FormData();
      formData.append("image", image);
      const res = await axios.post(`${enviroments.base_url}/images`, formData);
      values.imagen = res.data;
    } else {
      values.imagen = null;
    }

    values.documento_identidad = String(values.documento_identidad);
    values.rut = String(values.rut);

    if (!!!data_edit) {
      await dispatch(createNewClient(values));
    } else {
      values.id = data_edit.id;
      await dispatch(updateClient(values));
      close_edit({ status: false, data: null });
    }

    setOpen(false);
    setImage(null);
    setUrl("");
    reset();
  };

  useEffect(() => {
    dispatch(getAllBusinessName());
    dispatch(getAllRegions());
    dispatch(getAllPaises());
  }, []);

  useEffect(() => {
    if (!!data_edit) {
      reset({
        nombre: data_edit.nombre,
        tipo_razon_social: data_edit?.gen_tipo_razon_social?.id,
        rut: data_edit.rut,
        documento_identidad: data_edit.rut,
        region: data_edit.gen_region?.id,
        comuna: data_edit.gen_comuna?.id,
        direccion: data_edit.direccion,
        mail_contacto: data_edit.mail_contacto,
        hijo: data_edit.padre !== null ? 1 : 2,
        padre: data_edit?.padre,
        nombre_pago: data_edit.nombre_pago,
        correo_pago: data_edit.correo_pago,
        tipo_empresa: data_edit.tipo_empresa === "Privada" ? 1 : 2,
        pais_id: data_edit.pais_id,
      });
    }
  }, [data_edit, open_edit]);

  useEffect(() => {
    if (!!!data_edit && paises?.length) {
      setValue("pais_id", paises.find((el) => el.name === "Chile")?.id);
    }
  }, [paises, open]);

  useEffect(() => {
    if (regionWatcher) {
      dispatch(getCommuneByRegion(regionWatcher));
    }
  }, [regionWatcher]);

  const uploadImage = (e) => {
    setUrl(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  return (
    <div>
      {!isViewMode && (
        <Chip
          onClick={() => setOpen(true)}
          variant="contained"
          sx={{ textTransform: "none" }}
          label="Añadir Cliente"
          color="primary"
        />
      )}
      <Modal
        open={open || open_edit}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open || open_edit}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(handle_submit)}>
              <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-4">
                {!isViewMode && data_edit ? "EDITAR" : !isViewMode && "AÑADIR"} CLIENTE
              </div>
              <div className="max-xl:max-h-[520px] max-xl:overflow-y-auto max-xl:w-[350px] w-[800px]">
                <div className="w-full grid grid-cols-4 max-xl:grid-cols-1">
                  <div className="flex items-center flex-col justify-center">
                    <Avatar
                      alt="Remy Sharp"
                      src={(url && url) || (data_edit?.img && `${enviroments.img_endpoint}${data_edit.img}`)}
                      sx={{ width: 100, height: 100 }}
                    />
                    {!isViewMode && (
                      <>
                        <label htmlFor="file-upload" className={`custom-file-upload mx-10`}>
                          <FileUploadIcon sx={{ mr: 1 }} />
                          Imagen
                        </label>
                        <input
                          id="file-upload"
                          type="file"
                          accept="image/*"
                          {...register("imagen")}
                          onChange={uploadImage}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-span-3">
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="nombre"
                          name="nombre"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              disabled={isViewMode}
                              fullWidth
                              label="Nombre"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.nombre}
                              helperText={errors.nombre?.message}
                            />
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="tipo_razon_social"
                          name="tipo_razon_social"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              disabled={isViewMode}
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Razon Social (Nombre)"
                              error={errors.tipo_razon_social}
                              helperText={errors.tipo_razon_social?.message}
                              placeholder=""
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              {businessData?.map(
                                (option) =>
                                  option.id && (
                                    <MenuItem key={option.id} dense divider value={option.id}>
                                      {option.descripcion}
                                    </MenuItem>
                                  )
                              )}
                            </TextField>
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="pais_id"
                          name="pais_id"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              disabled={isViewMode}
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Pais"
                              error={errors.pais_id}
                              helperText={errors.pais_id?.message}
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              {paises?.map(
                                (option) =>
                                  option.id && (
                                    <MenuItem key={option.id} dense divider value={option.id}>
                                      {option.name}
                                    </MenuItem>
                                  )
                              )}
                            </TextField>
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        {isChilean ? (
                          <Controller
                            id="rut"
                            name="rut"
                            control={control}
                            rules={{
                              required: "* Campo requerido",
                              validate: {
                                validarRut: async (val) => {
                                  const bool = validateRUT(val);
                                  if (bool) {
                                    const duplicated = await validateDu(val);

                                    if (duplicated) {
                                      return "El Rut se encuentra en uso";
                                    } else {
                                      return true;
                                    }
                                  } else {
                                    return "Rut Invalido";
                                  }
                                },
                              },
                            }}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                size="small"
                                onChange={(event) => {
                                  const formattedValue = handleChangeRut(event);
                                  onChange(formattedValue);
                                }}
                                disabled={isViewMode}
                                value={value || ""}
                                label="RUT"
                                error={errors?.rut}
                                helperText={errors?.rut?.message}
                                placeholder=""
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: 0,
                                  },
                                }}
                              />
                            )}
                          />
                        ) : (
                          <Controller
                            id="documento_identidad"
                            name="documento_identidad"
                            control={control}
                            rules={{
                              required: "* Campo requerido",
                            }}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                size="small"
                                disabled={isViewMode}
                                type="text"
                                onChange={onChange}
                                value={value || ""}
                                label="Documento de Identidad"
                                error={errors?.documento_identidad}
                                helperText={errors?.documento_identidad?.message}
                                placeholder=""
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: 0,
                                  },
                                }}
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                    {isChilean && (
                      <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                        <div className="min-h-[65px] mb-1">
                          <Controller
                            id="region"
                            name="region"
                            control={control}
                            rules={{ required: "* Campo requerido" }}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                select
                                size="small"
                                disabled={isViewMode}
                                onChange={onChange}
                                value={value || ""}
                                label="Región"
                                error={errors.region}
                                helperText={errors.region?.message}
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: 0,
                                  },
                                }}
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: 300,
                                      },
                                    },
                                  },
                                }}
                              >
                                {regionData?.map(
                                  (option) =>
                                    option.id && (
                                      <MenuItem key={option.id} dense divider value={option.id}>
                                        {option.descripcion}
                                      </MenuItem>
                                    )
                                )}
                              </TextField>
                            )}
                          />
                        </div>
                        <div className="min-h-[65px] mb-1">
                          <Controller
                            id="comuna"
                            name="comuna"
                            control={control}
                            rules={{ required: "* Campo requerido" }}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                select
                                size="small"
                                onChange={onChange}
                                disabled={isViewMode}
                                value={value || ""}
                                label="Comunas"
                                error={errors.comuna}
                                helperText={errors.comuna?.message}
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: 0,
                                  },
                                }}
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: 300,
                                      },
                                    },
                                  },
                                }}
                              >
                                {comunas?.map(
                                  (option) =>
                                    option.id && (
                                      <MenuItem key={option.id} dense divider value={option.id}>
                                        {option.descripcion}
                                      </MenuItem>
                                    )
                                )}
                              </TextField>
                            )}
                          />
                        </div>
                      </div>
                    )}
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="direccion"
                          name="direccion"
                          control={control}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              disabled={isViewMode}
                              label="Dirección"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                            />
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="mail_contacto"
                          name="mail_contacto"
                          control={control}
                          rules={{
                            required: false,
                            validate: {
                              validarEmail: (val) => {
                                if (!!val) {
                                  const bool = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
                                  return bool ? true : "Email Invalido";
                                }

                                return true;
                              },
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Correo de contacto"
                              onChange={onChange}
                              disabled={isViewMode}
                              size="small"
                              error={errors.mail_contacto}
                              helperText={errors.mail_contacto?.message}
                              value={value || ""}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div
                      className={`grid ${
                        sonWatcher === 1 ? "grid-cols-2" : "grid-cols-1"
                      } max-xl:grid-cols-1 gap-5 max-xl:gap-0`}
                    >
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="hijo"
                          name="hijo"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              disabled={isViewMode}
                              value={value || ""}
                              label="Hijo"
                              error={errors.hijo}
                              helperText={errors.hijo?.message}
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              <MenuItem dense divider value={1}>
                                SI
                              </MenuItem>
                              <MenuItem dense divider value={2}>
                                NO
                              </MenuItem>
                            </TextField>
                          )}
                        />
                      </div>
                      {sonWatcher === 1 && (
                        <div className="min-h-[65px] mb-1">
                          <Controller
                            id="padre"
                            name="padre"
                            control={control}
                            rules={{ required: "* Campo requerido" }}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                select
                                size="small"
                                onChange={onChange}
                                value={value || ""}
                                label="Seleccionar Padre"
                                disabled={isViewMode}
                                error={errors.padre}
                                helperText={errors.padre?.message}
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: 0,
                                  },
                                }}
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: 300,
                                      },
                                    },
                                  },
                                }}
                              >
                                {clients?.map(
                                  (option) =>
                                    option.id && (
                                      <MenuItem key={option.id} dense divider value={option.id}>
                                        {option.nombre}
                                      </MenuItem>
                                    )
                                )}
                              </TextField>
                            )}
                          />
                        </div>
                      )}
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="nombre_pago"
                          name="nombre_pago"
                          control={control}
                          rules={{
                            required: false,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              disabled={isViewMode}
                              fullWidth
                              label="Nombre encargado de pago"
                              onChange={onChange}
                              size="small"
                              error={errors.nombre_pago}
                              helperText={errors.nombre_pago?.message}
                              value={value || ""}
                            />
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="correo_pago"
                          name="correo_pago"
                          control={control}
                          rules={{
                            required: false,
                            validate: {
                              validarEmail: (val) => {
                                if (!!val) {
                                  const bool = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
                                  return bool ? true : "Email Invalido";
                                }

                                return true;
                              },
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              disabled={isViewMode}
                              fullWidth
                              label="Correo encargado de pago"
                              onChange={onChange}
                              size="small"
                              error={errors.correo_pago}
                              helperText={errors.correo_pago?.message}
                              value={value || ""}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="tipo_empresa"
                          name="tipo_empresa"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              disabled={isViewMode}
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Tipo de Empresa"
                              error={errors.tipo_empresa}
                              helperText={errors.tipo_empresa?.message}
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              <MenuItem dense divider value={1}>
                                Privada
                              </MenuItem>
                              <MenuItem dense divider value={2}>
                                Pública
                              </MenuItem>
                            </TextField>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isViewMode && (
                <div className="flex items-center w-full justify-between max-xl:mt-5">
                  <Button type="submit" variant="outlined" fullWidth>
                    Enviar formulario
                  </Button>
                </div>
              )}
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
