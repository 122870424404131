import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button, Tooltip } from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "75%",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function PreFacturaItem({
  bodega,
  setBodega,
  asignados,
  setAsignados,
  search,
  setSearch,
  setSubTotalItems,
}) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSearch(bodega);
  };

  const handleTeamClick = (team) => {
    if (bodega.includes(team)) {
      const updatedBodega = bodega.filter((t) => t !== team);
      const updatedSearch = search.filter((t) => t !== team);
      setBodega(updatedBodega);
      setSearch(updatedSearch);
      setAsignados([...asignados, { ...team, cantidad_solic: 0 }]);
      setSubTotalItems((prevState) => prevState + team.valueCost);
    } else {
      const updatedAsignados = asignados.filter((t) => t !== team);
      let teamAdd = { ...team, cantidad: team.cantidad + parseInt(team.cant_back || 0), cant_back: 0 };
      setAsignados(updatedAsignados);
      setBodega([...bodega, teamAdd]);
      setSearch([...search, teamAdd]);
      setSubTotalItems((prevState) => prevState - team.valueCost);
    }
  };

  useEffect(() => {
    let res = bodega.filter((obj1) => !asignados.some((obj2) => obj2.id === obj1.id));
    setBodega(res);
    setSearch(res);
  }, [open]);

  const handleChange = (e) => {
    const filtered = bodega.filter((obj) => obj.nombre.toLowerCase().includes(e.target.value.toLowerCase()));
    setSearch(filtered);
  };

  const handleQuantityChange = (event, team) => {
    if (event.target.value > team.cantidad + parseInt(team.cant_back || 0) || event.target.value < 1) return;

    const updatedAsignados = asignados.map((t) => {
      if (t === team) {
        return { ...t, cantidad_solic: event.target.value };
      }
      return t;
    });
    setAsignados(updatedAsignados);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{ textTransform: "none", color: "white", border: "1px solid white" }}
        size="small"
      >
        AÑADIR
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(5px)" } } }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex h-[100%] flex-col justify-between">
              <div className="flex flex-col h-[40%]">
                <div className="bg-[#2B2C84]/70 text-white flex justify-between items-center min-h-[15%] mb-1">
                  <div className="pl-20">DISPONIBLES</div>
                  <div className="pr-20 text-black">
                    <input className="pl-2" type="text" placeholder="Buscar" onChange={handleChange} />
                    <SearchIcon sx={{ color: "white", marginLeft: 1 }} />
                  </div>
                </div>
                <div className="h-[85%] overflow-y-auto flex flex-col w-[100%] preFactura-scroll">
                  {search.length > 0 ? (
                    search.map((team) => (
                      <div
                        key={team.id}
                        className="bg-[#eef2fc] min-h-[40px] max-h-[40px] mb-1 flex items-center justify-between"
                      >
                        <div className="pl-5 w-full flex justify-between">
                          <div className="w-full">
                            <Tooltip title={team.codigo_loginteg || "S/D"}>
                              <span>{team.codigo_loginteg || "S/D"}</span>
                            </Tooltip>
                          </div>
                          <div className="w-full">
                            <Tooltip title={team.nombre || "S/D"}>
                              <span>{team.nombre.length > 20 ? team.nombre.slice(0, 20) + ".." : team.nombre}</span>
                            </Tooltip>
                          </div>
                          <div className="w-full">
                            <Tooltip title={team.lotes || "S/D"}>
                              <span>{team.lotes || "S/D"}</span>
                            </Tooltip>
                          </div>
                          <div className="w-full">
                            <Tooltip
                              title={
                                team.fecha_vencimiento ? dayjs(team.fecha_vencimiento).format("DD/MM/YYYY") : "S/D"
                              }
                            >
                              <span>
                                {team.fecha_vencimiento ? dayjs(team.fecha_vencimiento).format("DD/MM/YYYY") : "S/D"}
                              </span>
                            </Tooltip>
                          </div>
                          <div className="w-full flex items-center">Cantidad: {team.cantidad}</div>
                        </div>
                        <div className="pr-5">
                          <AddIcon onClick={() => handleTeamClick(team)} sx={{ cursor: "pointer" }} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div className="flex flex-col h-[40%]">
                <div className="bg-[#2B2C84]/70 text-white flex justify-center items-center min-h-[15%] mb-1">
                  ASIGNADOS
                </div>
                <div className="h-[85%] overflow-y-auto flex flex-col w-[100%] preFactura-scroll">
                  {asignados.length > 0 ? (
                    asignados.map((team) => (
                      <div
                        key={team.nombre} // Agregar una clave única
                        className="bg-[#eef2fc] min-h-[40px] max-h-[40px] mb-1 flex items-center justify-between"
                      >
                        <div className="pl-5 w-full flex justify-between">
                          <div className="w-full">
                            <Tooltip title={team.codigo_loginteg || "S/D"}>
                              <span>{team.codigo_loginteg || "S/D"}</span>
                            </Tooltip>
                          </div>
                          <div className="w-full">
                            <Tooltip title={team.nombre || "S/D"}>
                              <span>{team.nombre.length > 20 ? team.nombre.slice(0, 20) + ".." : team.nombre}</span>
                            </Tooltip>
                          </div>
                          <div className="w-full">
                            <Tooltip title={team.lotes || "S/D"}>
                              <span>{team.lotes || "S/D"}</span>
                            </Tooltip>
                          </div>
                          <div className="w-full">
                            <Tooltip
                              title={
                                team.fecha_vencimiento ? dayjs(team.fecha_vencimiento).format("DD/MM/YYYY") : "S/D"
                              }
                            >
                              <span>
                                {team.fecha_vencimiento ? dayjs(team.fecha_vencimiento).format("DD/MM/YYYY") : "S/D"}
                              </span>
                            </Tooltip>
                          </div>
                          <div className="w-full flex items-center">
                            Cantidad:
                            <input
                              type="number"
                              className="bg-[#eef2fc] w-[30px] border-b-2 mx-3 pl-1 input-number"
                              value={team.cantidad_solic}
                              onChange={(event) => handleQuantityChange(event, team)}
                            />
                          </div>
                        </div>
                        <div className="pr-5">
                          <RemoveIcon onClick={() => handleTeamClick(team)} sx={{ cursor: "pointer" }} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <Button variant="contained" onClick={handleClose}>
                Guardar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
