import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: "10px",

    borderBottom: "3px solid black",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& td, & th": {
    borderBottom: "1px solid #8c8c8c",
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: "none",
}));
export default function FacturaItemTable({ rows }) {
  return (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>DETALLE</StyledTableCell>
            <StyledTableCell align="left">CANTIDAD</StyledTableCell>
            <StyledTableCell align="left">LOTE</StyledTableCell>
            <StyledTableCell align="left">FECHA VENCIMIENTO</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 &&
            rows.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.inv_articulos?.nombre}
                </StyledTableCell>
                <StyledTableCell align="left">{row.cantidad}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.inv_articulos.lotes ? row.inv_articulos.lotes : "S/D"}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.inv_articulos.fecha_vencimiento
                    ? dayjs(row.inv_articulos.fecha_vencimiento).format("DD/MM/YYYY")
                    : "S/D"}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
