const textFieldSelectFormat = (array, condition = "withOutCondition") => {
  if (condition === "withOutCondition") {
    const optionMap = array.map((element) => ({
      id: element.id,
      label: element?.descripcion
        ? `${element?.codigo_loginteg?.toUpperCase()} - ${element?.descripcion?.toUpperCase()}`
        : `${element.codigo_loginteg.toUpperCase()} - ${element.nombre.toUpperCase()}`,
    }));

    return optionMap;
  } else if (condition === "withCondition") {
    const optionMap = array.map((element) => ({
      id: element.id,
      label: element.nombre,
    }));

    return optionMap;
  } else if (condition === "descripcion") {
    if (array.length) {
      const optionMap = array.map((element) => ({
        id: element.id,
        label: element.descripcion,
        modelo: element.inv_modelo,
      }));
      return optionMap;
    } else {
      return { id: 0, label: "NO HAY DISPONIBLES" };
    }
  }
};

export default textFieldSelectFormat;
