import { createSlice } from "@reduxjs/toolkit";
import formatDataModal from "../../../utils/formatDataModal";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";
import test, { generateAndSendPDF } from "../../../components/PDF/PdfComponent";

const initialState = {
  data: [],
  client_ots: [],
  tech_ots: [],
  ot_types: [],
  select: [],
  ot: {},
  success: false,
  redirect: false,
  firma_cliente: "",
  firma_tecnico: "",
};

const workOrdersSlice = createSlice({
  name: "workOrders",
  initialState,
  reducers: {
    setWorkOrders: (state, action) => {
      state.data = action.payload;
      state.success = true;
    },
    setOtSelect: (state, action) => {
      state.select = action.payload;
      state.success = true;
    },
    AddWorkOrder: (state, action) => {
      state.data = [...state.data, action.payload];
      state.success = true;
    },
    setAssigned: (state, action) => {
      const filtered = state.data.filter((order) => order.id !== action.payload.id);
      state.data = [...filtered, action.payload];
      state.success = true;
    },
    setRedirect: (state, action) => {
      state.redirect = action.payload;
    },
    setClientSignature: (state, action) => {
      state.firma_cliente = action.payload;
    },
    setTechnicalSignature: (state, action) => {
      state.firma_tecnico = action.payload;
    },
    setClientOts: (state, action) => {
      state.client_ots = action.payload;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setTechnicalOts: (state, action) => {
      state.tech_ots = action.payload;
      state.success = true;
    },
    setOT: (state, action) => {
      state.ot = action.payload;
      state.success = true;
    },
    setOtTypes: (state, action) => {
      state.ot_types = action.payload;
      state.success = true;
    },
    AddOtType: (state, action) => {
      state.ot_types = [...state.ot_types, action.payload];
      state.success = true;
    },
    updateOt_Type: (state, action) => {
      const updated = state.ot_types.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.ot_types = [...updated, action.payload];
      state.success = true;
    },
    deleteTypeOt: (state, action) => {
      const updated = state.ot_types.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.ot_types = updated;
      state.success = true;
    },
    setUpdatedObservation: (state, action) => {
      state.ot.ot_observacion_ot_observacion_otToot = action.payload;
      state.success = true;
    },
    setUpdatedImages: (state, action) => {
      state.ot.ot_imagen_ot_imagen_otToot = action.payload;
      state.success = true;
    },
  },
});

export const getAllWorkOrders = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const orders = await axiosInstance.get("/ot");
    dispatch(setWorkOrders(orders.data));
  } catch (error) {
    console.log(error);
  }
};

export const getOtSelect = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const orders = await axiosInstance.get("/ot/select");
    dispatch(setOtSelect(orders.data));
  } catch (error) {
    console.log(error);
  }
};

export const createWorkOrder = (form_values, cantidades) => async (dispatch) => {
  // eslint-disable-next-line
  const { equipos, ...rest } = form_values;
  try {
    const newOt = await axiosInstance.post("/ot", {
      ...form_values,
      equipos,
      cantidades,
    });
    if (newOt.status === 201) {
      dispatch(AddWorkOrder(newOt.data));
      dispatch(setRedirect(true));
    }
  } catch (error) {
    return error;
  }
};

export const updateWorkOrder = (form_values, cantidades, id) => async (dispatch) => {
  // eslint-disable-next-line
  const { equipos, ...rest } = form_values;

  try {
    const newOt = await axiosInstance.put("/ot/update-ot", {
      ...form_values,
      equipos,
      cantidades,
      id,
      obs_id: rest.ot_observacion_id,
    });
    // if (newOt.status === 201) {
    //   dispatch(AddWorkOrder(newOt.data));
    //   dispatch(setRedirect(true));
    // }
  } catch (error) {
    return error;
  }
};

export const assignWorkOrderToTechnical = (data) => async (dispatch) => {
  try {
    const assigned = await axiosInstance.put("/ot", data);
    dispatch(setAssigned(assigned.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Tecnico asignado con éxito",
        open: true,
      }),
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al asignar tecnico",
        open: true,
      }),
    );
    console.log(error);
  }
};

export const unassignWorkOrderToTechnical = (value) => async (dispatch) => {
  try {
    const assigned = await axiosInstance.delete(
      `/ot/unassign?id=${value.id}&ot_id=${value.ot_id}&codigo_telegram=${value.gen_usuario.codigo_telegram}&user_name=${value.gen_usuario.nombre}`,
    );
    dispatch(setAssigned(assigned.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Tecnico desasignado con éxito",
        open: true,
      }),
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al desasignar tecnico",
        open: true,
      }),
    );
    console.log(error);
  }
};

export const WorkerOrderActualySituation =
  (data, docs = []) =>
  async (dispatch) => {
    try {
      let dataToSend = [];
      if (data.length > 0) {
        const images = data.map((data) => data.rawImage);
        const formData = new FormData();

        images.forEach((image) => {
          formData.append("image", image);
        });

        const res = await axiosInstance.post("/images/multiple", formData);
        const imgsToSend = data.map((obj, index) => {
          delete obj.rawImage;
          return { ...obj, img: res.data[index].filename };
        });
        dataToSend = dataToSend.concat(imgsToSend);
      }
      if (docs.length > 0) {
        const documents = docs.map((doc) => doc.rawDoc);
        const formData = new FormData();

        documents.forEach((doc) => {
          formData.append("files", doc);
        });

        const response = await axiosInstance.post("/images/multiple/docs", formData);
        const docsToSend = docs.map((obj, index) => {
          delete obj.rawDoc;
          return { ...obj, img: response.data[index].filename, type: "doc" };
        });
        dataToSend = dataToSend.concat(docsToSend);
      }

      const ActualySituation = await axiosInstance.post("/ot/ActualySituation", dataToSend);
      dispatch(setUpdatedImages(ActualySituation.data));
      dispatch(
        setNotification({
          status: "success",
          message: "Imagen/es subidas con éxito",
          open: true,
        }),
      );
      return ActualySituation;
    } catch (error) {
      dispatch(
        setNotification({
          status: "error",
          message: "Error al subir la/s imagen/es",
          open: true,
        }),
      );
      return error;
    }
  };

export const updateObservation = (data) => async (dispatch) => {
  try {
    const response = await axiosInstance.put("/ot/observation", data);
    dispatch(setUpdatedObservation(response.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Observacion creada con éxito",
        open: true,
      }),
    );
    return response;
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear la observación",
        open: true,
      }),
    );
    return error;
  }
};

export const updateSignature = (data) => async (dispatch) => {
  try {
    const newData = { ...data };
    if (data.firma_tecnico) {
      const formData = new FormData();
      formData.append("image", data.firma_tecnico);
      const res = await axiosInstance.post("/images", formData);
      newData.firma_tecnico = res.data;
      dispatch(
        setNotification({
          status: "success",
          message: "Firma actualizada con exito",
          open: true,
        }),
      );
    }
    if (data.firma_cliente) {
      const formData = new FormData();
      formData.append("image", data.firma_cliente);
      const res = await axiosInstance.post("/images", formData);
      newData.firma_cliente = res.data;
      dispatch(
        setNotification({
          status: "success",
          message: "Firma actualizada con exito",
          open: true,
        }),
      );
    }

    const response = await axiosInstance.put("/ot/signature", newData);

    if (response.data?.cerrada) {
      const blob = await generateAndSendPDF(response.data, newData.current_name, newData.current_rut);

      const formData = new FormData();
      formData.append("file", blob);
      const filename = await axiosInstance.post("/images/pdf", formData);

      if (filename.data.length) {
        const res = await axiosInstance.post("/ot/send-mail", {
          ot_current: response.data,
          current_user_mail: newData.current_user_mail,
          filename: filename.data,
        });
      }

      window.location.reload();
    }

    return response;
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar la firma",
        open: true,
      }),
    );
    return error;
  }
};

export const endOt = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.put("/ot/finish-ot", data);
    dispatch(setAssigned(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Orden de trabajo finalizada con éxito",
        open: true,
      }),
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al finalizar orden de trabajo",
        open: true,
      }),
    );
    console.log(error);
  }
};

export const getOTByClientID = (client_id) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/ot/client/${client_id}`);
    dispatch(setClientOts(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getOTByTechnicalWorkerId = (tech_id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get(`/ot/technical/${tech_id}`);
    const formatedData = res.data.map((data) => {
      return {
        ...data.ot,
        f_ot: data.ot.f_ot.slice(0, -2),
      };
    });
    dispatch(setTechnicalOts(formatedData));
  } catch (error) {
    console.log(error);
  }
};

export const getOTByID = (ot_id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get(`/ot/${ot_id}`);
    dispatch(setOT(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const editContact = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.put("/ot/info", data);
    dispatch(
      setNotification({
        status: "success",
        message: "Cliente editado con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar cliente",
        open: true,
      }),
    );
  }
};

export const getOtTypes = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/ot/types");
    dispatch(setOtTypes(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const createOtTypes = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.post("/ot/types", values);
    dispatch(AddOtType(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Creado con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear tipo",
        open: true,
      }),
    );
  }
};

export const updateOtType = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.put("/ot/types", values);
    dispatch(updateOt_Type(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Editado con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar tipo",
        open: true,
      }),
    );
  }
};

export const deleteOtType = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.put(`/ot/types/${id}`);
    dispatch(deleteTypeOt(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Eliminado con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar tipo",
        open: true,
      }),
    );
  }
};

export const deleteOt = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.put(`ot/deleteOt?id=${id}`);
    return "eliminado";
  } catch (error) {}
};

export const reabrirOT = (val) => async (dispatch) => {
  try {
    const res = await axiosInstance.put("/ot/re-open", val);
    dispatch(updateOt_Type(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Reabierta con exito",
        open: true,
      }),
    );
    return res.data.id;
  } catch (error) {
    console.log(error);
  }
};

export const {
  AddWorkOrder,
  setWorkOrders,
  setRedirect,
  setAssigned,
  setClientSignature,
  setTechnicalSignature,
  setClientOts,
  setSuccess,
  setTechnicalOts,
  setOT,
  setOtTypes,
  AddOtType,
  deleteTypeOt,
  setUpdatedObservation,
  setUpdatedImages,
  updateOt_Type,
  setOtSelect,
} = workOrdersSlice.actions;

export default workOrdersSlice.reducer;
