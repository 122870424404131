import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";
import dayjs from "dayjs";

const initialState = {
  telegrams: [],
  users: [],
  mails: [],
  status: "idle",
};

const gestionEnvios = createSlice({
  name: "gestionEnvios",
  initialState,
  reducers: {
    setter: (state, action) => {
      state[action.payload.setter] = action.payload.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    update: (state, action) => {
      state[action.payload.setter] = [
        ...state[action.payload.setter].filter((el) => el.id !== action.payload.payload.id),
        action.payload.payload,
      ];
    },
  },
});

export const getAllTelegrams = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/gestion-envios/getTelegrams");
    dispatch(setter({ setter: "telegrams", payload: response.data }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getAllUsersEnvios = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/gestion-envios/users");
    dispatch(setter({ setter: "users", payload: response.data }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const createTelegram = (id, values) => async (dispatch) => {
  const response = await axiosInstance.post(`gestion-envios/createUsersTelegram/${id}`, values);
  dispatch(update({ setter: "telegrams", payload: response.data }));
};

export const getAllMails = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/gestion-envios/getMails");
    dispatch(setter({ setter: "mails", payload: response.data }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const createMail = (id, values) => async (dispatch) => {
  const response = await axiosInstance.post(`gestion-envios/createUsersMails/${id}`, values);
  dispatch(update({ setter: "mails", payload: response.data }));
};

export const { setter, setStatus, update } = gestionEnvios.actions;

export default gestionEnvios.reducer;
