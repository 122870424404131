import {
  Page,
  Text,
  View,
  Document,
  Font,
  pdf,
  Image,
  PDFViewer,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import dateFormatter from "../../utils/dateFormatter";
import thousandSeparator from "../../utils/thousandSeparator";
import GrandViewRegular from "../../assets/Grandview.ttf";
import GrandViewBold from "../../assets/GrandviewBold.ttf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@mui/material";
import enviroments from "../../utils/enviroments";

const months = [
  { nombre: "Enero", value: 1 },
  { nombre: "Febrero", value: 2 },
  { nombre: "Marzo", value: 3 },
  { nombre: "Abril", value: 4 },
  { nombre: "Mayo", value: 5 },
  { nombre: "Junio", value: 6 },
  { nombre: "Julio", value: 7 },
  { nombre: "Agosto", value: 8 },
  { nombre: "Septiembre", value: 9 },
  { nombre: "Octubre", value: 10 },
  { nombre: "Noviembre", value: 11 },
  { nombre: "Diciembre", value: 12 },
];

const style = StyleSheet.create({
  page: {
    padding: 10,
    display: "flex",
    fontFamily: "GrandView",
  },
  borderedView: {
    border: "1px",
    borderStyle: "double",
    height: "auto",
    padding: "10px",
    paddingBottom: "0",
    fontSize: "14px",
    marginBottom: "5px",
  },
  titleBorderedView: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    border: "1px",
    borderStyle: "double",
    height: "auto",
    padding: "10px",
    paddingBottom: "0",
    fontSize: "14px",
    marginBottom: "5px",
    backgroundColor: "#2b2c84",
  },
  h2: {
    color: "white",
    fontSize: "12px",
    textTransform: "capitalize",
    fontWeight: "semibold",
  },
  h1: {
    color: "black",
    fontSize: "15px",
    textTransform: "capitalize",
    fontWeight: "semibold",
    width: "100%",
    textAlign: "center",
    marginTop: 15,
  },
  separator: {
    textAlign: "center",
    border: "1px",
    borderStyle: "double",
    height: "auto",
    padding: "5px",
    paddingBottom: "0",
    fontSize: "14px",
    marginBottom: "5px",
    backgroundColor: "#2b2c84",
  },
  borderedViewMinSignature: {
    border: "1px",
    borderStyle: "double",
    height: "auto",
    minHeight: "220",
    padding: "10px",
    paddingBottom: "0",
    fontSize: "14px",
    marginBottom: "5px",
  },
  borderHeigthSignature: {
    border: "1px",
    borderStyle: "double",
    height: "auto",
    minHeight: "350px",
    padding: "10px",
    paddingBottom: "0",
    fontSize: "14px",
    marginBottom: "5px",
  },
  borderedViewOBS: {
    border: "1px",
    borderStyle: "double",
    height: "auto",
    fontSize: "10px",
    fontWeight: "bold",
    paddingTop: "5px",
    textAlign: "center",
    marginBottom: "5px",
  },
  labelTextLeft: {
    display: "flex",
    alignItems: "center",
    width: "100px",
    fontWeight: "semibold",
    fontSize: "9px",
    textTransform: "capitalize",
    color: "#232323",
    backgroundColor: "#dbe6eb",
    paddingVertical: "5px",
    borderRadius: "3px",
    paddingHorizontal: "2px",
  },
  labelTextLeftOt: {
    display: "flex",
    alignItems: "center",
    width: "85px",
    fontWeight: "semibold",
    fontSize: "9px",
    textTransform: "capitalize",
    color: "#232323",
    backgroundColor: "#dbe6eb",
    paddingVertical: "5px",
    borderRadius: "3px",
    paddingLeft: "2px",
  },
  labelTextRight: {
    width: "80px",
    fontWeight: "bold",
    fontSize: "9px",
    marginLeft: "10px",
    textTransform: "capitalize",
    color: "#232323",
    backgroundColor: "#dbe6eb",
    paddingVertical: "5px",
    borderRadius: "3px",
    paddingHorizontal: "2px",
  },
  labelTextRightSM: {
    width: "90px",
    fontSize: "9px",
    textTransform: "uppercase",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  title: {
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "white",
  },
  flexView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "5px",
  },
  input: {
    border: "1px solid black",
    width: "60%",
    fontSize: "10px",
    paddingLeft: "4px",
    paddingTop: "4px",
    // height: "15px",
  },
  inputSM: {
    border: "1px solid black",
    width: "200px",
    fontSize: "10px",
    paddingLeft: "4px",
    paddingTop: "4px",
  },
  inputFull: {
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    width: "90%",
    fontSize: "10px",
    paddingLeft: "4px",
    paddingTop: "4px",
    marginLeft: "4px",
    paddingVertical: "3px",
    borderRadius: "3px",
    paddingHorizontal: "2px",
    borderColor: "gray",
    color: "gray",
  },
  inputRight: {
    border: "1px solid black",
    marginLeft: "1px",
    width: "90%",
    fontSize: "10px",
    paddingLeft: "4px",
    paddingTop: "4px",
  },
  labelFI: {
    fontSize: "9px",
    textTransform: "uppercase",
    fontWeight: "bold",
    width: "112px",
  },
  labelFT: {
    fontSize: "9px",
    textTransform: "uppercase",
    width: "130px",
    marginLeft: "10px",
    fontWeight: "bold",
  },
  inputFT: {
    border: "1px solid black",
    width: "180px",
    fontSize: "10px",
    paddingLeft: "4px",
    paddingTop: "4px",
  },
  category: {
    width: "100%",
    fontSize: "10px",
    textTransform: "uppercase",
    borderBottom: "1px solid black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  categoryRigth: {
    fontSize: "10px",
    textTransform: "uppercase",
  },
  labelCategory: {
    fontSize: "9px",
    width: "130px",
    marginTop: "5px",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  labelCategoryThin: {
    fontSize: "9px",
    width: "130px",
    marginTop: "5px",
    textTransform: "uppercase",
  },
  categoryView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  categoryInput: {
    border: "1px solid black",
    width: "100px",
    minWidth: "100px",
    marginLeft: "10px",
    fontSize: "10px",
    paddingLeft: "4px",
    paddingTop: "4px",
  },
  subtotalView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
    marginTop: "10px",
  },
  subTotalInput: {
    border: "1px solid black",
    width: "100px",
    fontSize: "10px",
    paddingLeft: "4px",
    height: "auto",
    paddingTop: "4px",
    backgroundColor: "#f7dac8",
  },
  labelCategoryThinMargin: {
    fontSize: "9px",
    width: "200px",
    marginTop: "5px",
    textTransform: "uppercase",
    marginLeft: "10px",
    marginRight: "10px",
  },
  observation: {
    marginTop: "5px",
    fontSize: "10px",
    textDecoration: "underline",
    lineHeight: "2",
  },
  borderedSignature: {
    height: "auto",
    fontSize: "14px",
    marginBottom: "5px",
    display: "flex",
    flexDirection: "row",
    rowGap: "10px",
  },
  borderedViewSignature: {
    border: "1px",
    borderStyle: "double",
    padding: "10px",
    position: "relative",
    fontSize: "14px",
    width: "100%",
    height: "125px",
  },
  borderedViewSignatureLeft: {
    border: "1px",
    marginRight: "5px",
    borderStyle: "double",
    padding: "10px",
    position: "relative",
    fontSize: "14px",
    width: "100%",
    height: "125px",
  },
  borderedViewSignatureLeftDeclined: {
    border: "1px",
    marginRight: "5px",
    borderStyle: "double",
    padding: "10px",
    position: "relative",
    fontSize: "14px",
    width: "100%",
    height: "125px",
    backgroundColor: "#FF9999",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F2F2F2",
    minHeight: "100px",
  },
  tableResumenContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F2F2F2",
    marginBottom: "25px",
  },
  headContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  tableHead: {
    fontSize: "10px",
    padding: "2px",
    fontWeight: "semibold",
    color: "#262626",
    width: "100%",
    textAlign: "center",
  },
  tableRow: {
    fontSize: "10px",
    padding: "2px",
    fontWeight: "normal",
    color: "#262626",
    width: "100%",
    textAlign: "center",
    border: "0.5px",
  },
});

Font.register({
  family: "GrandView",
  fonts: [
    {
      src: GrandViewRegular,
    },
    {
      src: GrandViewBold,
      fontWeight: "bold",
    },
  ],
});

const sampleText = "aa";

function dividirTexto(texto, longitudMaxima = 1450) {
  const palabras = texto.split(" ");
  const partes = [];
  let parteActual = "";

  for (let i = 0; i < palabras.length; i++) {
    const palabra = palabras[i];

    if ((parteActual + " " + palabra).length <= longitudMaxima) {
      parteActual += (parteActual === "" ? "" : " ") + palabra;
    } else {
      partes.push(parteActual);
      parteActual = palabra;
    }
  }

  if (parteActual !== "") {
    partes.push(parteActual);
  }

  return partes;
}

const PDFRendicion = ({ data, rows }) => {
  let otName = "";

  data.orden_servicio?.length &&
    data.orden_servicio.forEach((el) => {
      otName += el.cliente_ot_clienteTocliente.nombre + " - ";
    });
  return (
    <PDFDownloadLink
      fileName={`Rendición - ID (${data.id})`}
      document={
        <Document>
          <Page size="A4" style={style.page}>
            <View style={style.titleBorderedView}>
              <Text style={style.title}>{`Rendición de fondos N° ${data?.id} - (${data?.usuario.nombre})`}</Text>
              <Image
                source={`${window.location.origin}/tellus-white.png`}
                style={{
                  height: "25px",
                  width: "90px",
                  marginTop: "-5px",
                  marginBottom: "1px",
                }}
              />
            </View>
            <View style={style.borderedView}>
              <View style={style.flexView}>
                <View style={style.flexView}>
                  <Text style={style.labelTextLeftOt}>Orden de Trabajo: </Text>
                  <Text style={style.inputFull}>
                    {data.orden_servicio?.length ? otName : "Sin Orden de Trabajo"}
                    {/* {data.orden_servicio?.id ? data?.orden_servicio.id : "Sin orden de trabajo"} -
                    {data.orden_servicio?.id &&
                      data?.orden_servicio.cliente_ot_clienteTocliente.nombre.slice(0, 30) + ".."} */}
                  </Text>
                </View>
                {/* <View style={style.flexView}>
                  <Text style={style.labelTextRight}>Devolución: </Text>
                  <Text style={style.inputFull}>$ {thousandSeparator(data?.devolucion)}</Text>
                </View> */}
              </View>
              <View style={style.flexView}>
                <View style={style.flexView}>
                  <Text style={style.labelTextLeft}>Devolución: </Text>
                  <Text style={style.inputFull}>$ {thousandSeparator(data?.devolucion)}</Text>
                </View>
                <View style={style.flexView}>
                  <Text style={style.labelTextRight}>Banco: </Text>
                  <Text style={style.inputFull}>
                    {data?.bancos ? data?.bancos?.nombre.slice(0, 30) + ".." : "EFECTIVO"}{" "}
                  </Text>
                </View>
              </View>
              <View style={style.flexView}>
                <View style={style.flexView}>
                  <Text style={style.labelTextLeft}>Monto a rendir: </Text>
                  <Text style={style.inputFull}>${thousandSeparator(data?.monto_rendir)}</Text>
                </View>
                <View style={style.flexView}>
                  <Text style={style.labelTextRight}>Cuenta N.º: </Text>
                  <Text style={style.inputFull}>{thousandSeparator(data?.numero_cuenta)}</Text>
                </View>
              </View>
              <View style={style.flexView}>
                <View style={style.flexView}>
                  <Text style={style.labelTextLeft}>Fecha: </Text>
                  <Text style={style.inputFull}>{dateFormatter(data?.fecha_rendicion)}</Text>
                </View>
                <View style={style.flexView}>
                  <Text style={style.labelTextRight}>Moneda: </Text>
                  <Text style={style.inputFull}>{data?.moneda?.nombre}</Text>
                </View>
              </View>
            </View>
            <View style={style.separator}>
              <Text style={style.h2}>GASTOS</Text>
            </View>
            <View style={style.tableContainer}>
              <View style={style.headContainer}>
                <Text style={style.tableHead}>Proyecto</Text>
                <Text style={style.tableHead}>Fecha</Text>
                <Text style={style.tableHead}>N° Dcto</Text>
                {/* <Text style={style.tableHead}>Detalle</Text> */}
                <Text style={style.tableHead}>Monto ($)</Text>
                <Text style={style.tableHead}>Tipo gasto</Text>
                <Text style={style.tableHead}>Categoria</Text>
              </View>
              {data?.rendicion_detalle.map((rendicion) => (
                <View key={rendicion.id} style={style.headContainer}>
                  <Text style={style.tableRow}>
                    {rendicion.facturacion_proyectos[0]?.nombre.length > 10
                      ? `${rendicion.facturacion_proyectos[0]?.nombre.slice(0, 10)}...`
                      : rendicion.facturacion_proyectos[0]?.nombre || "S/D"}
                  </Text>
                  <Text style={style.tableRow}>{dateFormatter(rendicion.fecha)}</Text>
                  <Text style={style.tableRow}>{rendicion.numero_documento}</Text>
                  {/* <Text style={style.tableRow}>{rendicion.detalle}</Text> */}
                  <Text style={style.tableRow}>$ {thousandSeparator(rendicion.monto)}</Text>
                  <Text style={style.tableRow}>{rendicion.tipo_gasto}</Text>
                  <Text style={style.tableRow}>{rendicion.rendicion_tipo?.nombre.slice(0, 9) + ".."}</Text>
                </View>
              ))}
            </View>
            <View style={style.separator}>
              <Text style={style.h2}>RESUMEN</Text>
            </View>
            <View style={style.tableResumenContainer}>
              <View style={style.headContainer}>
                <Text style={style.tableHead}>RESUMEN</Text>
                <Text style={style.tableHead}>BOLETAS</Text>
                <Text style={style.tableHead}>COMPROBANTE</Text>
                <Text style={style.tableHead}>FACTURA</Text>
                <Text style={style.tableHead}>TOTAL</Text>
              </View>
              <View style={style.headContainer}>
                <Text style={style.tableRow}>Monto en ({data.moneda.nombre})</Text>
                <Text style={style.tableRow}>
                  ${" "}
                  {thousandSeparator(
                    rows
                      .filter((row) => row.expenseType === 1 || row.expenseType === "BOLETA")
                      .reduce(
                        (acc, obj) =>
                          acc +
                          parseInt(
                            obj.amount === "" || obj.amount === null
                              ? 0
                              : typeof obj.amount === "string" && obj.amount?.includes(".")
                              ? obj.amount.replaceAll(".", "")
                              : obj.amount,
                          ),
                        0,
                      ),
                  )}
                </Text>
                <Text style={style.tableRow}>
                  ${" "}
                  {thousandSeparator(
                    rows
                      .filter((row) => row.expenseType === 3 || row.expenseType === "COMPROBANTE")
                      .reduce(
                        (acc, obj) =>
                          acc +
                          parseInt(
                            obj.amount === "" || obj.amount === null
                              ? 0
                              : typeof obj.amount === "string" && obj.amount?.includes(".")
                              ? obj.amount.replaceAll(".", "")
                              : obj.amount,
                          ),
                        0,
                      ),
                  )}
                </Text>
                <Text style={style.tableRow}>
                  ${" "}
                  {thousandSeparator(
                    rows
                      .filter((row) => row.expenseType === 2 || row.expenseType === "FACTURA")
                      .reduce(
                        (acc, obj) =>
                          acc +
                          (obj.amount === "" || obj.amount === null
                            ? 0
                            : typeof obj.amount === "string" && obj.amount.includes(".")
                            ? parseInt(obj.amount.replaceAll(".", ""))
                            : parseInt(obj.amount)),
                        0,
                      ),
                  )}
                </Text>
                <Text style={style.tableRow}>
                  ${" "}
                  {thousandSeparator(
                    rows.reduce(
                      (acc, obj) =>
                        acc +
                        (obj.amount === "" || obj.amount === null
                          ? 0
                          : typeof obj.amount === "string" && obj.amount?.includes(".")
                          ? parseInt(obj.amount.replaceAll(/\./g, ""))
                          : parseInt(obj.amount)),
                      0,
                    ),
                  )}
                </Text>
              </View>
            </View>
            <View style={style.separator}>
              <Text style={style.h2}>ESTADO</Text>
            </View>
            <Text style={style.h1}>{data?.rendicion_estado?.nombre}</Text>
          </Page>
        </Document>
      }
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <div>
            <Button size="small" sx={{ color: "white", fontSize: "15px", fontWeight: "bold" }}>
              Descargar Rendición
              <FileDownloadIcon />
            </Button>
          </div>
        ) : (
          <div>
            <Button size="small" sx={{ color: "white", fontSize: "15px", fontWeight: "bold" }}>
              Descargar Rendición
              <FileDownloadIcon />
            </Button>
          </div>
        )
      }
    </PDFDownloadLink>
    /*     </PDFViewer> */
  );
  {
    /* <PDFViewer style={{ width: "100vw", height: "100vh" }}> */
  }
};
/* }; */

export default PDFRendicion;
