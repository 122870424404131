import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllServices } from "../../../redux/slices/services";
import PreFacturaServices from "../../modal/PreFacturaServices";
import PreFacturaServicesTable from "../../tables/PreFacturaTable/PreFacturaServicesTable";

export default function PreFacturaAccordionServices({ asignados, setter }) {
  const [itemsBack, setItemsBack] = useState([]);
  const [search, setSearch] = useState([]);
  const { services } = useSelector((state) => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllServices());
  }, [dispatch]);

  useEffect(() => {
    setItemsBack(services);
    setSearch(services);
  }, [services]);

  return (
    <div>
      <div className="mb-5">
        <div className="flex justify-end bg-[#2b2c84]/70 h-[40px]">
          <div className="text-white flex items-center justify-center w-[80%]">SERVICIOS</div>
          <div className="w-[20%] flex justify-end pr-5 items-center">
            <PreFacturaServices
              bodega={itemsBack}
              setBodega={setItemsBack}
              asignados={asignados}
              setAsignados={setter}
              search={search}
              setSearch={setSearch}
            />
          </div>
        </div>
        <div>
          <PreFacturaServicesTable isService rows={asignados} />
        </div>
      </div>
    </div>
  );
}
