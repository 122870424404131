import { useState } from "react";
import logo from "../../assets/loginteg.svg";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { handleSideBar } from "../../redux/slices/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { clearAccessToken } from "../../redux/slices/auth";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import enviroments from "../../utils/enviroments";
import Chip from "@mui/material/Chip";

function Navbar() {
  const data = useSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleHamburguer = () => {
    dispatch(handleSideBar());
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    dispatch(clearAccessToken());
    window.location.reload();
  };

  const handleProfile = () => {
    return navigate("/profile");
  };

  const settings = [
    { name: "Perfil", handler: handleProfile, icon: PersonIcon },
    { name: "Cerrar Sesion", handler: handleLogout, icon: LogoutIcon },
  ];

  return (
    location.pathname !== "/login" && (
      <div className="w-full min-h-[65px] px-5 flex justify-between fixed top-0 items-center shadow z-50 bg-white">
        <div className="flex items-center">
          <div className="relative flex justify-center lg:w-[200px] mr-5">
            <Link to={"/"}>
              <img className="w-[120px] select-none" src={logo} alt="logo" />
            </Link>
          </div>
          <div>
            <IconButton onClick={handleHamburguer}>
              <MenuIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
        <div className="flex items-center">
          <Chip className="mr-6 pointer-events-none select-none capitalize" label={data?.nombre} />
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, marginRight: 3 }}>
            <Avatar alt="Remy Sharp" src={data?.img && `${enviroments.img_endpoint}${data.img}`} />
          </IconButton>
          <Menu
            sx={{ mt: "48px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                <Typography onClick={setting.handler && setting.handler} textAlign="start" className="w-full">
                  <setting.icon fontSize="small" sx={{ marginRight: 1 }} />
                  {setting.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    )
  );
}
export default Navbar;
