import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencies } from "../../redux/slices/moneda";
import { getOtSelect, setOT } from "../../redux/slices/workOrders";
import { getBanks } from "../../redux/slices/banco";
import { getAllProyectsBilling } from "../../redux/slices/facturacion";
import { createAnticipo, getExpenseClaimCategories } from "../../redux/slices/fundAccounting";
import { getAllUsers } from "../../redux/slices/users";
import CustomSelectMultiple from "../../components/CustomSelectMultiple";
import thousandSeparator from "../../utils/thousandSeparator";
import removeNonNumeric from "../../utils/removeNaN";

const isNum = (val) => {
  if (/^(0|[1-9][0-9]*)$/.test(val)) return true;
  return "El campo es numerico";
};

export default function RegistrarAnticipo() {
  const { currentUser } = useSelector((state) => state.auth);
  const { select } = useSelector((state) => state.workOrders);
  const { currency } = useSelector((state) => state.currency);
  const { banks } = useSelector((state) => state.banks);
  const { data: userList } = useSelector((state) => state.users);
  const { facturacionProyectos: proyects } = useSelector((state) => state.facturacion);
  const [successData, setSuccessData] = useState(null);
  const [ots, setOts] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getCurrencies());
    dispatch(getOtSelect());
    dispatch(getBanks());
    dispatch(getAllProyectsBilling());
    dispatch(getExpenseClaimCategories());
  }, [dispatch]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const submit = async (v) => {
    v.ots = ots;
    v.anticipo = parseInt(v.anticipo.replaceAll(".", ""));
    const data = await dispatch(createAnticipo(v));
    if (data.status === 201) {
      setSuccessData(data.data);
      setOts([]);
      reset();
    }
  };

  useEffect(() => {
    if (userList.length && currentUser) {
      const finded = userList.find((el) => el.id === currentUser.id);

      if (finded) {
        setValue("nombre_completo", finded.id);
      }
    }
  }, [userList, currentUser]);

  return (
    <div className="py-5 min-h-[calc(100vh-200px)] max-w-[1442px] mx-auto flex justify-center flex-col w-full">
      <Divider textAlign="center" sx={{ mb: 4 }}>
        <Chip sx={{ minWidth: "140px" }} color="primary" label="INFORMACIÓN DE FONDO POR RENDIR" />
      </Divider>
      <div className="gap-5 flex mb-7">
        <Controller
          id="anticipo"
          name="anticipo"
          rules={{ required: "* El campo es requerido" }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              id={"anticipo"}
              label={"Valor Anticipo"}
              value={value || ""}
              onChange={(e) => onChange(thousandSeparator(removeNonNumeric(e.target.value)))}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#707070",
                },
              }}
              type={"text"}
              error={errors?.anticipo?.message}
              helperText={errors?.anticipo?.message}
            />
          )}
        />
        <Controller
          id="nombre_completo"
          name="nombre_completo"
          rules={{ required: "* El campo es requerido" }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              id={"nombre_completo"}
              label={"Nombre completo del receptor"}
              value={value || ""}
              onChange={onChange}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#707070",
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                },
              }}
              type={"text"}
              error={errors?.nombre_completo?.message}
              helperText={errors?.nombre_completo?.message}
              select
            >
              {userList.length &&
                userList?.map((option) => (
                  <MenuItem key={option.id} value={option?.id || ""}>
                    {option.nombre}
                  </MenuItem>
                ))}
            </TextField>
          )}
        />
      </div>
      <Divider textAlign="center" sx={{ mb: 4 }}>
        <Chip sx={{ minWidth: "140px" }} color="primary" label="INFORMACIÓN REQUERIDA PARA RENDICIÓN" />
      </Divider>
      <div className="gap-5 flex mb-7 w-full">
        <CustomSelectMultiple
          id="ot_id"
          name="ot_id"
          width={"170px"}
          label={"Orden de trabajo"}
          value={ots}
          disabled={false}
          size="standard"
          onChange={(e) => setOts(e)}
          options={[{ id: -1, nombre: "Sin orden de trabajo" }].concat(
            select.map((el) => ({
              id: el.id,
              nombre: `OT ${el.id} - ${el.cliente_ot_clienteTocliente.nombre}`,
            })),
          )}
        />
      </div>
      <div className="gap-5 flex mb-7">
        <Controller
          id={"fecha"}
          control={control}
          name={"fecha"}
          rules={{ required: true }}
          defaultValue={dayjs()}
          render={({ field: { onChange, value } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"} localeText={esES}>
              <DatePicker
                value={value || ""}
                onChange={(date) => onChange(date)}
                slotProps={{ textField: { fullWidth: true } }}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          )}
        />
        <Controller
          id="banco_id"
          name="banco_id"
          rules={{ required: "* El campo es requerido" }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              id={"banco_id"}
              label={"Banco"}
              value={value || ""}
              onChange={onChange}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#707070",
                },
              }}
              type={"text"}
              error={errors?.banco_id?.message}
              helperText={errors?.banco_id?.message}
              select
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                },
              }}
            >
              {banks.length &&
                [{ id: -1, nombre: "EFECTIVO" }].concat(banks)?.map((option) => (
                  <MenuItem key={option.id} value={option?.id || ""}>
                    {option.nombre}
                  </MenuItem>
                ))}
            </TextField>
          )}
        />
      </div>
      <div className="gap-5 flex mb-7">
        <Controller
          id="cuenta_nro"
          name="cuenta_nro"
          rules={{ required: "* El campo es requerido", validate: isNum }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">N.° </InputAdornment>,
              }}
              id={"cuenta_nro"}
              label={"Cuenta"}
              value={value || ""}
              onChange={onChange}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#707070",
                },
              }}
              type={"text"}
              error={errors?.cuenta_nro?.message}
              helperText={errors?.cuenta_nro?.message}
            />
          )}
        />
        <Controller
          id="moneda_id"
          name="moneda_id"
          rules={{ required: "* El campo es requerido" }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              id={"moneda_id"}
              label={"Tipo de Moneda"}
              value={value || ""}
              onChange={onChange}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#707070",
                },
              }}
              type={"text"}
              error={errors?.moneda_id?.message}
              helperText={errors?.moneda_id?.message}
              select
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                },
              }}
            >
              {currency.length &&
                currency?.map((option) => (
                  <MenuItem key={option.id} value={option?.id || ""}>
                    {option.nombre}
                  </MenuItem>
                ))}
            </TextField>
          )}
        />
      </div>

      <div className="w-full flex flex-col items-center mt-5">
        <Button sx={{ minWidth: 350, mb: 1 }} onClick={handleSubmit(submit)} variant="contained">
          Registar fondo por rendir
        </Button>
        <span className="text-neutral-500">El valor de anticipo se le restara a la devolución</span>
      </div>
      <SimpleDialog open={!!successData} setOpen={setSuccessData} data={successData} />
    </div>
  );
}

function SimpleDialog({ open, setOpen, data }) {
  const handleClose = () => {
    setOpen(null);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¡Anticipo y rendición creados con éxito!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Nos complace informarte que el proceso de creación del anticipo ha sido ejecutado de manera exitosa y ya
            puedes visualizarlo desde el listado correspondiente. <br /> Además, queremos destacar que la rendición{" "}
            <b className="text-green-500">(N°{data?.rendicion_gastos?.id})</b> ha sido habilitada para que puedas
            registrar los gastos asociados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
