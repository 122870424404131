import DataGridComponent from "../../components/tables/DataGrid";
import useDataGrid from "../../hooks/useDataGrid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnticipos, updatePaymentMethod } from "../../redux/slices/fundAccounting";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import uploadFiles from "../../utils/UploadFiles";
import enviroments from "../../utils/enviroments";

export const dataColumns = [
  // { value: "id", title: "id", minWidth: 170 },
  { value: "rendicionId", title: "Rendición N.°", minWidth: 170 },
  { value: "name", title: "Nombre Completo", minWidth: 170 },
  { value: "anticipo", title: "Anticipo", minWidth: 170 },
  { value: "devolucion", title: "Devolución", minWidth: 170 },
  // { value: "bancoText", title: "Banco", minWidth: 170 },
  // { value: "monedaText", title: "Moneda", minWidth: 170 },
  { value: "anticipo_actions", title: "Acciones", minWidth: 100 },
];

export default function AnticiposList() {
  const dispatch = useDispatch();
  const { anticipo } = useSelector((state) => state.fundAccounting);
  const { currentUser } = useSelector((state) => state.auth);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [alreadyAproved, setAlreadyAproved] = useState(false);
  const [file, setFile] = useState(null);

  const initialState = {
    data: null,
    open: false,
  };

  const [check, setCheck] = useState(initialState);

  const handleSubmit = async () => {
    const vals = { metodo: paymentMethod, id: check.data.id, file };
    if (file) {
      vals.file = await uploadFiles("docs", file);
    } else {
      vals.file = null;
    }

    await dispatch(updatePaymentMethod(vals));
    onClose();
  };

  useEffect(() => {
    if (!!check.data?.metodo) {
      setPaymentMethod(check.data.metodo);
      setAlreadyAproved(true);
    }
  }, [check]);

  useEffect(() => {
    if (currentUser.id) {
      dispatch(getAnticipos());
    }
  }, [dispatch, currentUser.id]);

  const [column] = useDataGrid(dataColumns, null, "", false, setCheck);

  const onClose = () => {
    setCheck(initialState);
    setAlreadyAproved(false);
    setPaymentMethod("");
    setFile(null);
  };

  return (
    <>
      <div className="mt-2">
        <DataGridComponent
          columns={column}
          rows={[...anticipo].sort((a, b) => {
            return new Date(b.rendicion_gastos.fecha_rendicion) - new Date(a.rendicion_gastos.fecha_rendicion);
          })}
          success={true}
        />
      </div>
      <Dialog
        open={check.open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Procedimiento para Confirmar Anticipo Saldado"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>
              {!alreadyAproved &&
                "Antes de marcar un anticipo como saldado, es necesario elegir el método de pago utilizado. No olvides este paso importante antes de confirmar el anticipo como saldado."}
              {alreadyAproved && "El anticipo fue marcado como saldado con el siguiente método: "}
            </span>
            <TextField
              label="Método utilizado"
              onChange={(e) => setPaymentMethod(e.target.value)}
              sx={{ mt: 2 }}
              fullWidth
              select
              value={paymentMethod}
              disabled={alreadyAproved}
            >
              <MenuItem value="Transferencia">Transferencia</MenuItem>
              <MenuItem value="Efectivo">Efectivo</MenuItem>
              <MenuItem value="Devolución a Colaborador">Devolución a Colaborador</MenuItem>
            </TextField>
          </DialogContentText>
          {paymentMethod === "Transferencia" && !check.data.metodo && (
            <div className="mt-2 w-full">
              <div>
                <Button
                  disabled={alreadyAproved}
                  color={file ? "success" : "primary"}
                  component="label"
                  variant="contained"
                  fullWidth
                >
                  Subir Archivo
                  <input type="file" hidden onChange={(e) => setFile(e.target.files[0])} accept="application/pdf" />
                </Button>
              </div>
            </div>
          )}
          {check?.data?.file && (
            <div className="mt-2">
              <Button component="label" variant="contained" fullWidth>
                <a href={`${enviroments.img_endpoint}${check.data.file}`} target="_blank" download>
                  Descargar Comprobante
                </a>
              </Button>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cerrar</Button>
          {!alreadyAproved && (
            <Button onClick={handleSubmit} autoFocus>
              Aprobar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
