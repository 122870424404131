import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, Chip, MenuItem, Avatar, Autocomplete, IconButton } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { getAllPaises } from "../../redux/slices/paises";
import { getAllBusinessName } from "../../redux/slices/businessName";
import { getAllRegions, getCommuneByRegion } from "../../redux/slices/regions";
import { validateRUT } from "validar-rut";
import axiosInstance from "../../utils/axiosInstance";
import { createNewClient, updateClient } from "../../redux/slices/clients";
import axios from "axios";
import enviroments from "../../utils/enviroments";
import { getAllCharges } from "../../redux/slices/charges";
import { getAllDepartments } from "../../redux/slices/deparments";
import { getAllRoles, setRole } from "../../redux/slices/roles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { createNewUser, updateUser } from "../../redux/slices/users";
import FreeSoloCreateOptionDialog from "../FreeSoloCreateOptionDialog";
import ChargeDialog from "../FreeSoloCreateOptionDialog/ChargeDialog";
import DepartmentDialog from "../FreeSoloCreateOptionDialog/DepartmentDialog";
import RolesDialog from "../FreeSoloCreateOptionDialog/RolesDialog";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function NewUserForm({ open_edit, data_edit, close_edit }) {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("password");

  const { data: chargesData } = useSelector((state) => state.charges);
  const { data: departmentData } = useSelector((state) => state.department);
  const { data: rolesData } = useSelector((state) => state.roles);
  const { paises } = useSelector((state) => state.paises);

  const [autocompleteErrors, setAutocompleteErrors] = React.useState({
    charge: false,
    department: false,
    role: false,
  });

  const [chargeSelected, setChargeSelected] = React.useState(null);
  const [departmentSelected, setDepartmentSelected] = React.useState(null);
  const [roleSelected, setRoleSelected] = React.useState(null);

  const [url, setUrl] = React.useState();
  const [image, setImage] = React.useState(null);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ mode: "onSubmit" });

  const handleClose = () => {
    if (data_edit) {
      close_edit({ status: false, data: null });
    }
    setChargeSelected(null);
    setDepartmentSelected(null);
    setRoleSelected(null);
    setOpen(false);
    setImage(null);
    setAutocompleteErrors({});
    setUrl("");
    reset({
      nombre: "",
      rut: "",
      documento_identidad: "",
      pais_id: "",
      usuario: "",
      jefatura: "",
      cargo: "",
      direccion: "",
      telefono: "",
      departamento: "",
      rol: "",
      mail_corporativo: "",
      mail_personal: "",
      codigo_telegram: "",
      approver: "",
    });
  };

  const countryWatcher = watch("pais_id");
  const regionWatcher = watch("region");
  const isChilean = paises.find((el) => el.id === countryWatcher)?.name === "Chile";

  const validateDu = async (val) => {
    const { data } = await axiosInstance.get(`/cliente/rut/validate?id=${data_edit?.id || null}&parentsRut=${null}`);
    if (data?.arr) {
      return data.arr.includes(val) ? "El rut se encuentra en uso" : false;
    } else {
      return false;
    }
  };

  const handleChangeRut = (event) => {
    let value = event.target.value.trim().replace(/[^\dkK]/g, ""); // Elimina caracteres no válidos
    let formattedValue = "";

    if (value.length > 1) {
      formattedValue = value.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "-" + value.slice(-1);
    } else {
      formattedValue = value;
    }

    return formattedValue;
  };

  const handle_submit = async (values) => {
    if (!!!roleSelected || !!!chargeSelected || !!!departmentSelected) return;

    if (image !== null) {
      const formData = new FormData();
      formData.append("image", image);
      const res = await axios.post(`${enviroments.base_url}/images`, formData);
      values.img = res.data;
    } else {
      values.img = null;
    }

    values.documento_identidad = String(values.documento_identidad);
    values.rut = String(values.rut);
    values.approver = values.approver === "true" ? true : false;
    if (isChilean) values.documento_identidad = "undefined";
    values.cargo = chargeSelected?.id;
    values.departamento = departmentSelected?.id;
    values.rol = roleSelected?.id;

    if (!!!data_edit) {
      await dispatch(createNewUser(values));
    } else {
      values.id = data_edit.id;
      dispatch(updateUser(values));
      close_edit({ status: false, data: null });
    }

    setOpen(false);
    setImage(null);
    setUrl("");
    reset();
  };

  useEffect(() => {
    dispatch(getAllCharges());
    dispatch(getAllDepartments());
    dispatch(getAllRoles());
    dispatch(getAllPaises());
  }, []);

  useEffect(() => {
    if (!!data_edit) {
      setChargeSelected(data_edit.gen_cargo);
      setDepartmentSelected(data_edit.gen_departamento);
      setRoleSelected(data_edit.gen_rol);
      reset({
        nombre: data_edit.nombre,
        action_mode: data_edit.action_mode,
        rut: data_edit.rut,
        documento_identidad: data_edit.rut,
        pais_id: data_edit.paisId,
        usuario: data_edit.usuario,
        jefatura: data_edit.jefatura,
        cargo: data_edit.gen_cargo.id,
        direccion: data_edit.direccion,
        telefono: data_edit.telefono,
        departamento: data_edit.gen_departamento.id,
        rol: data_edit.gen_rol.id,
        mail_corporativo: data_edit.mail_corporativo,
        mail_personal: data_edit.mail_personal,
        codigo_telegram: data_edit.codigo_telegram,
        approver: data_edit.approver ? "true" : "false",
        executeOt: data_edit.executeOt ? "true" : "false",
      });
    }
  }, [data_edit, open_edit]);

  useEffect(() => {
    if (!!!data_edit && paises?.length) {
      setValue("pais_id", paises.find((el) => el.name === "Chile")?.id);
    }
  }, [paises, open]);

  useEffect(() => {
    if (regionWatcher) {
      dispatch(getCommuneByRegion(regionWatcher));
    }
  }, [regionWatcher]);

  const uploadImage = (e) => {
    setUrl(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  return (
    <div>
      <Chip
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ textTransform: "none" }}
        label="Añadir Usuario"
        color="primary"
      />
      <Modal
        open={open || open_edit}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open || open_edit}>
          <Box sx={style}>
            <form>
              <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-4">
                {data_edit ? "EDITAR" : "AÑADIR"} USUARIO
              </div>
              <div className="max-xl:max-h-[520px] max-xl:overflow-y-auto max-xl:w-[350px] w-[800px]">
                <div className="w-full grid grid-cols-4 max-xl:grid-cols-1">
                  <div className="flex items-center flex-col justify-center">
                    <Avatar
                      alt="Remy Sharp"
                      src={(url && url) || (data_edit?.img && `${enviroments.img_endpoint}${data_edit.img}`)}
                      sx={{ width: 100, height: 100 }}
                    />
                    <label htmlFor="file-upload" className={`custom-file-upload mx-10`}>
                      <FileUploadIcon sx={{ mr: 1 }} />
                      Imagen
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      {...register("imagen")}
                      onChange={uploadImage}
                    />
                  </div>
                  <div className="col-span-3">
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="nombre"
                          name="nombre"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Nombre"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.nombre}
                              helperText={errors.nombre?.message}
                            />
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="usuario"
                          name="usuario"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Usuario"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.usuario}
                              helperText={errors.usuario?.message}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="pais_id"
                          name="pais_id"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Pais"
                              error={errors.pais_id}
                              helperText={errors.pais_id?.message}
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              {paises?.map(
                                (option) =>
                                  option.id && (
                                    <MenuItem key={option.id} dense divider value={option.id}>
                                      {option.name}
                                    </MenuItem>
                                  )
                              )}
                            </TextField>
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        {isChilean ? (
                          <Controller
                            id="rut"
                            name="rut"
                            control={control}
                            rules={{
                              required: "* Campo requerido",
                              validate: {
                                validarRut: async (val) => {
                                  const bool = validateRUT(val);
                                  if (bool) {
                                    // const duplicated = await validateDu(val);
                                    const duplicated = false;

                                    if (duplicated) {
                                      return "El Rut se encuentra en uso";
                                    } else {
                                      return true;
                                    }
                                  } else {
                                    return "Rut Invalido";
                                  }
                                },
                              },
                            }}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                size="small"
                                onChange={(event) => {
                                  const formattedValue = handleChangeRut(event);
                                  onChange(formattedValue);
                                }}
                                value={value || ""}
                                label="RUT"
                                error={errors?.rut}
                                helperText={errors?.rut?.message}
                                placeholder=""
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: 0,
                                  },
                                }}
                              />
                            )}
                          />
                        ) : (
                          <Controller
                            id="documento_identidad"
                            name="documento_identidad"
                            control={control}
                            rules={{
                              required: "* Campo requerido",
                            }}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                size="small"
                                type="text"
                                onChange={onChange}
                                value={value || ""}
                                label="Documento de Identidad"
                                error={errors?.documento_identidad}
                                helperText={errors?.documento_identidad?.message}
                                placeholder=""
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: 0,
                                  },
                                }}
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="jefatura"
                          name="jefatura"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Jefatura"
                              error={errors.jefatura}
                              helperText={errors.jefatura?.message}
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              {chargesData?.map(
                                (option) =>
                                  option.id && (
                                    <MenuItem key={option.id} dense divider value={option.id}>
                                      {option.descripcion}
                                    </MenuItem>
                                  )
                              )}
                            </TextField>
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <FreeSoloCreateOptionDialog
                          name="cargo"
                          label={"Cargo"}
                          Form={ChargeDialog}
                          renderOption="descripcion"
                          options={chargesData?.filter((el) => el.tipo === "Usuario")}
                          setValue={setChargeSelected}
                          value={chargeSelected}
                          error={autocompleteErrors.charge}
                          onChange={(newValue, toggleOpen, setDialogValue) => {
                            if (typeof newValue === "string") {
                              setTimeout(() => {
                                toggleOpen(true);
                                setDialogValue({
                                  descripcion: newValue,
                                });
                              });
                            } else if (newValue && newValue.inputValue) {
                              toggleOpen(true);
                              setDialogValue({
                                descripcion: newValue.inputValue,
                              });
                            } else {
                              setChargeSelected(newValue);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="direccion"
                          name="direccion"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Dirección"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.direccion}
                              helperText={errors.direccion?.message}
                            />
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="telefono"
                          name="telefono"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Teléfono"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.telefono}
                              helperText={errors.telefono?.message}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <FreeSoloCreateOptionDialog
                          name="departamento"
                          label={"Departamento"}
                          Form={DepartmentDialog}
                          renderOption="descripcion"
                          options={departmentData}
                          setValue={setDepartmentSelected}
                          value={departmentSelected}
                          error={autocompleteErrors.department}
                          onChange={(newValue, toggleOpen, setDialogValue) => {
                            if (typeof newValue === "string") {
                              setTimeout(() => {
                                toggleOpen(true);
                                setDialogValue({
                                  descripcion: newValue,
                                });
                              });
                            } else if (newValue && newValue.inputValue) {
                              toggleOpen(true);
                              setDialogValue({
                                descripcion: newValue.inputValue,
                              });
                            } else {
                              setDepartmentSelected(newValue);
                            }
                          }}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <FreeSoloCreateOptionDialog
                          name="rol"
                          label={"Seleccionar Rol"}
                          Form={RolesDialog}
                          renderOption="descripcion"
                          options={rolesData}
                          setValue={setRoleSelected}
                          value={roleSelected}
                          error={autocompleteErrors.role}
                          onChange={(newValue, toggleOpen, setDialogValue) => {
                            if (typeof newValue === "string") {
                              setTimeout(() => {
                                toggleOpen(true);
                                setDialogValue({
                                  descripcion: newValue,
                                });
                              });
                            } else if (newValue && newValue.inputValue) {
                              toggleOpen(true);
                              setDialogValue({
                                descripcion: newValue.inputValue,
                              });
                            } else {
                              setRoleSelected(newValue);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="mail_corporativo"
                          name="mail_corporativo"
                          control={control}
                          rules={{
                            required: "* Campo requerido",
                            validate: {
                              isMail: (val) => {
                                if (/^[\w.%+-]+@loginteg\.cl$/.test(val)) {
                                  return true;
                                } else {
                                  return "El dominio debe ser loginteg.cl";
                                }
                              },
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Correo Corporativo"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.mail_corporativo}
                              helperText={errors.mail_corporativo?.message}
                            />
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="mail_personal"
                          name="mail_personal"
                          control={control}
                          rules={{
                            required: "* Campo requerido",
                            validate: {
                              isMail: (val) => {
                                if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val)) {
                                  return true;
                                } else {
                                  return "El email es invalido";
                                }
                              },
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Correo Personal"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.mail_personal}
                              helperText={errors.mail_personal?.message}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="codigo_telegram"
                          name="codigo_telegram"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Código Telegram"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.codigo_telegram}
                              helperText={errors.codigo_telegram?.message}
                            />
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="clave"
                          name="clave"
                          control={control}
                          rules={{ required: !!!data_edit && "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              type={type}
                              label="Contaseña"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.clave}
                              helperText={errors.clave?.message}
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    onClick={() => {
                                      setType((prev) => (prev === "password" ? "text" : "password"));
                                    }}
                                  >
                                    {type === "password" ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                  </IconButton>
                                ),
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="approver"
                          name="approver"
                          rules={{ required: "* Campo requerido" }}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Autorizador Vacaciones"
                              error={errors.approver}
                              helperText={errors.approver?.message}
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              <MenuItem dense divider value={"true"}>
                                SI
                              </MenuItem>
                              <MenuItem dense divider value={"false"}>
                                NO
                              </MenuItem>
                            </TextField>
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="action_mode"
                          name="action_mode"
                          rules={{ required: "* Campo requerido" }}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Modo de acción"
                              error={errors.approver}
                              helperText={errors.approver?.message}
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              <MenuItem dense divider value={"edit"}>
                                Edición
                              </MenuItem>
                              <MenuItem dense divider value={"view"}>
                                Visualización
                              </MenuItem>
                            </TextField>
                          )}
                        />
                      </div>
                    </div>
                    <div className="min-h-[65px] mb-1">
                      <Controller
                        id="executeOt"
                        name="executeOt"
                        rules={{ required: "* Campo requerido" }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            select
                            size="small"
                            onChange={onChange}
                            value={value || ""}
                            label="OT Técnico"
                            error={errors.approver}
                            helperText={errors.approver?.message}
                            sx={{
                              "& .MuiFormHelperText-root": {
                                marginLeft: 0,
                              },
                            }}
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    maxHeight: 300,
                                  },
                                },
                              },
                            }}
                          >
                            <MenuItem dense divider value={"true"}>
                              SI
                            </MenuItem>
                            <MenuItem dense divider value={"false"}>
                              NO
                            </MenuItem>
                          </TextField>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center w-full justify-between max-xl:mt-5">
                <Button
                  onClick={(e) => {
                    const errors_tmp = {};

                    if (!!!roleSelected) errors_tmp.role = true;
                    if (!!!chargeSelected) errors_tmp.charge = true;
                    if (!!!departmentSelected) errors_tmp.department = true;

                    setAutocompleteErrors(errors_tmp);

                    return handleSubmit(handle_submit)(e);
                  }}
                  variant="outlined"
                  fullWidth
                >
                  Enviar formulario
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
