import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import { Button, Backdrop, Fade, Box, MenuItem, TextField, TableContainer, Table } from "@mui/material";
import logo from "../../assets/loginteg.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getAllClients, getClientById, resetClientById } from "../../redux/slices/clients";
import { getProductCliente } from "../../redux/slices/invProducts";
import {
  createWorkOrder,
  updateWorkOrder,
  setRedirect,
  getOtTypes,
  getOTByID,
  setOT,
} from "../../redux/slices/workOrders";
import FacebookCircularProgress from "../FacebookLoading/FacebookLoading";
import Chip from "@mui/material/Chip";
import RepairPartOT from "../modal/RepairPartOT";
import { setNotification } from "../../redux/slices/notification";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import { getAllContactMeans } from "../../redux/slices/contactMeans";
import { validateRUT } from "validar-rut";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import dateFormatter from "../../utils/dateFormatter";
import Modal from "@mui/material/Modal";
import DeleteOtModal from "../modal/DeleteOtModal";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers";

const validarRut = (value, isRutValidationRequired) => {
  if (isRutValidationRequired) {
    return validateRUT(value);
  } else {
    return true;
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "95vh",
  "@media (max-width: 1400px)": {
    width: "95%",
  },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 1,
  "@media (min-width: 768px)": {
    p: 2,
  },
};
export default function WorkOrderEdit({ order, open, setter, client }) {
  const handleClose = () => {
    setter({ order: null, open: false });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <EditableWorkOrderFormOt order={order} id={client} open={open} handleClose={handleClose} />
        </Box>
      </Fade>
    </Modal>
  );
}

const columns = [
  { title: "ID", value: "id" },
  { title: "Equipo", value: "equipo" },
  { title: "Modelo", value: "modelo" },
  { title: "Nº serie", value: "serie" },
  { title: "Fecha de instalacion", value: "instalacion" },
  { title: "Fecha de garantia", value: "garantia" },
  { title: "Acciones", value: "acciones" },
];

const columns_rp = [
  { title: "Equipo" },
  { title: "Código Loginteg" },
  { title: "Nombre" },
  { title: "N° Modelo" },
  { title: "Serie" },
  { title: "Cantidad disponible" },
  { title: "Cantidad deseada" },
];

const columns_articulo = [
  { title: "Código Loginteg:" },
  { title: "Nombre" },
  { title: "Lote" },
  { title: "Fecha de vencimiento" },
  { title: "Cantidad" },
  { title: "Cantidad Solicitada" },
];

const columns_servicio = [{ title: "Nombre" }, { title: "Cantidad" }];

function EditableWorkOrderFormOt({ id, order, open, handleClose }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth.currentUser);
  const { clienteProduct } = useSelector((state) => state.invProducts);
  const { currentUser } = useSelector((state) => state.auth);
  const { client, data: clientData } = useSelector((state) => state.clients);
  const { data: contactMeans } = useSelector((state) => state.contactMeans);
  const { redirect, ot_types, ot } = useSelector((state) => state.workOrders);
  const [products, setProducts] = useState([]);
  const [selectProduct, setSelectProduct] = useState("");
  const [cantidades, setCantidades] = useState({});
  const [disabledRepairPartDeleteOption, setDisabledRepairPartDeleteOption] = useState([]);
  const [isRutValidationRequired, setIsRutValidationRequired] = useState(true);
  const [selectMessage, setSelectMessage] = useState({
    bool: false,
    message: "",
  });
  const navigate = useNavigate();

  const validarRut = (value, isRutValidationRequired) => {
    if (isRutValidationRequired) {
      return validateRUT(value);
    } else {
      return true;
    }
  };


  useEffect(() => {
    if (open) {
      dispatch(getOTByID(order));
      dispatch(getClientById(id));
      dispatch(getProductCliente(id));
      dispatch(getOtTypes());
      dispatch(getAllContactMeans());
      dispatch(getAllClients());
    }

    if (redirect === true) return navigate("/create-ot");

    return () => {
      dispatch(resetClientById());
      dispatch(setRedirect(false));
      dispatch(setOT({}));
    };
  }, [dispatch, id, redirect]);

  useEffect(() => {
    if (products.length) {
      setSelectMessage({
        bool: false,
        message: "",
      });
    }
  }, [products]);

  const equipo = ot?.ot_equipo_ot_equipo_otToot;
  const repairPartsOT = ot?.ot_repuesto_ot_repuesto_otToot;

  useEffect(() => {
    if (equipo?.length) {
      equipo.forEach((equipment) => {
        const repairParts = equipment.inv_equipo.inv_tipo_equipo.inv_repuesto;
        const equipmentID = equipment.inv_equipo.id; // 23

        repairParts.forEach((repairPart) => {
          const repairPartOT = repairPartsOT.find(
            (part) => part.equipo === equipmentID && part.repuesto === repairPart.id
          );

          const cantidad = repairPartOT ? repairPartOT.cantidad : 0;

          if (cantidades.hasOwnProperty(repairPart.id)) {
            cantidades[repairPart.id] = {
              ...cantidades[repairPart.id],
              [equipmentID]: cantidad,
              ot_id: ot.id,
              resp: currentUser.id,
            };
          } else {
            cantidades[repairPart.id] = {
              cantidad_total: repairPart.cantidad,
              [equipmentID]: cantidad,
              ot_id: ot.id,
              resp: currentUser.id,
            };
          }
        });

        setCantidades({ ...cantidades });
        setProducts(equipo.map((equipo) => equipo.inv_equipo));
      });
    }
  }, [equipo]);

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  // const clientWatcher = watch("resp_cliente");

  // useEffect(() => {
  //   const clientFillData = clientData.find((el) => el.id === clientWatcher);
  //   if (clientFillData) {
  //     setValue("rut_cliente", clientFillData.rut);
  //     setValue("resp_correo", clientFillData.mail_contacto);
  //   }
  // }, [clientWatcher]);

  let telefono1 = "";
  let telefono2 = "";
  let contacto = "PRINCIPAL";

  const handleProduct = (e) => {
    if (selectProduct === "") return;
    const find = products?.find((prod) => prod.id === selectProduct);
    if (find) return;

    const newProduct = clienteProduct?.find((prod) => prod.id === selectProduct);

    if (newProduct?.inv_tipo_equipo) {
      if (!products.length) {
        const repuestos = {};

        newProduct.inv_tipo_equipo.inv_repuesto.forEach((repuesto) => {
          repuestos[repuesto.id] = {
            cantidad_total: repuesto.cantidad,
            [newProduct.id]: 0,
          };
        });

        setCantidades({ ...cantidades, ...repuestos });
      } else {
        newProduct.inv_tipo_equipo.inv_repuesto.forEach((repuesto) => {
          if (cantidades.hasOwnProperty(repuesto.id)) {
            cantidades[repuesto.id] = {
              ...cantidades[repuesto.id],
              [newProduct.id]: 0,
            };
          } else {
            cantidades[repuesto.id] = {
              cantidad_total: repuesto.cantidad,
              [newProduct.id]: 0,
            };
          }
        });

        setCantidades({ ...cantidades });
      }
    }

    setProducts([...products, newProduct]);
  };

  const handleDeleteProducts = (id) => {
    for (const key in cantidades) {
      if (cantidades[key][id] > 0) {
        dispatch(
          setNotification({
            open: true,
            message: "Este equipo contiene repuestos asignados",
            status: "warning",
          })
        );
        return;
      }
    }
    setProducts((prevState) => prevState.filter((prod) => prod.id !== id));
  };

  Object.keys(client)?.length > 0 &&
    client.contacto_contacto_clienteTocliente.forEach((contacto) => {
      if (contacto.principal === true) {
        telefono1 = contacto.telefono1;
        telefono2 = contacto.telefono2;
      }
    });

  const submit = async (values) => {
    if (ot?.ot_observacion_ot_observacion_otToot[0]?.id)
      values.ot_observacion_id = ot?.ot_observacion_ot_observacion_otToot[0]?.id;

    values.cliente = id;
    let equipos = [];
    products.forEach((prod) => equipos.push(prod.id));
    values.equipos = equipos;
    values.garantia = values.garantia === "true" ? true : false;
    values.responsable = data.id;
    if (!values.horario_atencion) {
      values.horario_atencion = dayjs(ot?.horario_atencion) || dayjs();
    }
    if (!values.f_ejecucion) {
      values.f_ejecucion = dayjs(ot?.f_ejecucion);
    } else {
      values.f_ejecucion = dayjs(values.f_ejecucion).subtract(1, "day");
    }
    values.resp_cliente = parseInt(values.resp_cliente);
    await dispatch(updateWorkOrder(values, cantidades, order));
    handleClose();
  };

  return client.id && ot.id ? (
    <form className=" overflow-y-auto max-h-full sidebar-scroll-edit-modal" onSubmit={handleSubmit(submit)}>
      <div className="pr-2">
        <section>
          <div className="mb-3 grid grid-cols-4 bg-[#2B2C84] rounded text-[white] pt-2 pb-2">
            <div className="col-span-3 flex justify-center">INFORMACIÓN DEL CLIENTE - OT {ot.id}</div>
            <div className="flex justify-end pr-10">
              <DeleteOtModal id={ot.id} status={ot.cerrada} />
            </div>
          </div>
          <div className="md:flex">
            <div className="w-full md:w-[50%] flex items-center md:pr-8  mb-1">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                nombre:
              </label>
              <TextField
                size="small"
                fullWidth
                disabled
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                id="filled-disabled"
                value={client?.nombre}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                rut:
              </label>
              <TextField
                size="small"
                fullWidth
                disabled
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                id="filled-disabled"
                value={client?.rut}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
          </div>
          <div className="md:flex">
            <div className="w-full md:w-[50%] flex md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start md:pl-5 rounded mr-1 leading-4">
                comuna:
              </label>
              <TextField
                size="small"
                fullWidth
                disabled
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                id="filled-disabled"
                value={client?.comuna}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                direccion:
              </label>
              <TextField
                size="small"
                disabled
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                fullWidth
                id="filled-disabled"
                value={client?.direccion}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
          </div>
          <div className="md:flex">
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                contacto:
              </label>
              <TextField
                size="small"
                fullWidth
                disabled
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                id="filled-disabled"
                value={contacto}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                telefono:
              </label>
              <TextField
                size="small"
                fullWidth
                disabled
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                id="filled-disabled"
                value={telefono2}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
          </div>
          <div className="md:flex">
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                garantia:
              </label>
              <TextField
                size="small"
                fullWidth
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                id="filled-disabled"
                select
                variant="outlined"
                required
                defaultValue={String(ot?.garantia)}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("garantia")}
              >
                <MenuItem key={1} value={"true"}>
                  EQUIPO CON GARANTIA
                </MenuItem>
                <MenuItem key={2} value={"false"}>
                  EQUIPO SIN GARANTIA
                </MenuItem>
              </TextField>
            </div>
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                celular:
              </label>
              <TextField
                size="small"
                disabled
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                fullWidth
                id="filled-disabled"
                value={telefono1}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
          </div>
          <div className="md:flex">
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                horario de atencion:
              </label>
              <Controller
                id="horario_atencion"
                control={control}
                name="horario_atencion"
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider adapterLocale={"es"} dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      format="DD/MM/YYYY HH:mm"
                      onChange={onChange}
                      value={value}
                      defaultValue={ot?.horario_atencion ? dayjs(ot?.horario_atencion) : null}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          inputProps: { style: { fontSize: "14px" } },
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                email:
              </label>
              <TextField
                size="small"
                fullWidth
                disabled
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                id="filled-disabled"
                value={client?.mail_contacto}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
          </div>
          <div className="md:flex">
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                cliente resp:
              </label>
              <TextField
                size="small"
                fullWidth
                id="filled-disabled"
                defaultValue={ot?.contactoId}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  },
                }}
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                select
                variant="outlined"
                required
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("resp_cliente")}
              >
                {client?.contacto_contacto_clienteTocliente
                  .filter((el) => el.estado === true)
                  ?.map((contactMean) => (
                    <MenuItem key={contactMean.id} value={contactMean.id}>
                      {contactMean.nombre}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="w-full md:w-[50%]  md:pr-8 mb-1 items-center ">
              <div className="flex w-full">
                <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                  email resp:
                </label>
                <TextField
                  size="small"
                  fullWidth
                  required
                  id="filled-disabled"
                  defaultValue={ot?.resp_correo}
                  error={Boolean(errors?.resp_correo)}
                  InputProps={{
                    endAdornment: (
                      <div className="w-12 text-xs sm:w-32 text-red-500">
                        {errors?.resp_correo ? "Email invalido" : ""}
                      </div>
                    ),
                    style: { fontSize: "14px" },
                  }}
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#707070",
                    },
                  }}
                  {...register("resp_correo", {
                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  })}
                />
              </div>
            </div>
          </div>
          <div className="md:flex">
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                Rut cliente:
              </label>
              <TextField
                size="small"
                fullWidth
                id="filled-disabled"
                defaultValue={ot?.rut_cliente}
                inputProps={{
                  style: { fontSize: "14px", textTransform: "capitalize" },
                }}
                variant="outlined"
                label={errors.rut_cliente?.type === "validarRut" ? "El RUT es invalido" : ""}
                required
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("rut_cliente", {
                  required: true,
                  validate: {
                    validarRut: (val) => {
                      return validarRut(val, isRutValidationRequired);
                    },
                  },
                })}
              />
            </div>
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                Medio de Contacto:
              </label>
              <TextField
                size="small"
                fullWidth
                id="filled-disabled"
                defaultValue={ot?.medio_contacto}
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                select
                variant="outlined"
                required
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("medio_contacto")}
              >
                {contactMeans?.map((contactMean) => (
                  <MenuItem key={contactMean.id} value={contactMean.id}>
                    {contactMean.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="md:flex">
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                Tipo:
              </label>
              <TextField
                size="small"
                fullWidth
                id="filled-disabled"
                defaultValue={ot?.tipo_ot}
                select
                variant="outlined"
                required
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("tipo_ot")}
              >
                {ot_types?.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                costo estimado:
              </label>
              <TextField
                size="small"
                fullWidth
                id="filled-disabled"
                defaultValue={ot?.costo}
                InputProps={{
                  style: { fontSize: "14px" },
                }}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("costo")}
              />
            </div>
          </div>

          <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 mt-3 sm:mt-0">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              Fecha de Ejecución{" "}
            </label>
            <Controller
              id="f_ejecucion"
              control={control}
              name="f_ejecucion"
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                  <DatePicker
                    onChange={onChange}
                    value={value}
                    defaultValue={ot?.f_ejecucion ? dayjs(ot?.f_ejecucion).add(1, "day") : null}
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                        inputProps: { style: { fontSize: "14px" } },
                      },
                    }}
                  />
                </LocalizationProvider>
              )}
            />
          </div>
        </section>
        <section className="flex flex-col">
          <div className="mt-3 mb-2 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">EQUIPOS</div>
          <div className="flex w-full md:w-[40%] mb-2">
            <TextField
              size="small"
              select
              fullWidth
              label="Seleccionar Equipo"
              InputLabelProps={{ sx: { fontSize: "14px" } }}
              onChange={(e) => setSelectProduct(e.target.value)}
              InputProps={{ style: { fontSize: "14px" } }}
              defaultValue=""
              error={selectMessage.bool}
              helperText={selectMessage.message}
            >
              {clienteProduct ? (
                clienteProduct?.map((prod, index) => (
                  <MenuItem key={index} value={prod.id}>
                    {prod.inv_tipo_equipo.descripcion + " - " + prod.modelo + " - " + prod.serie}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={1} value={1} disabled>
                  No hay equipos
                </MenuItem>
              )}
            </TextField>
            <Button sx={{ ml: 1 }} size="small" variant="contained" onClick={(e) => handleProduct(e)}>
              Añadir
            </Button>
          </div>
          <div className="max-md:!hidden">
            <TableContainer sx={{ position: "relative" }} className="mb-5 bg-slate-100 rounded p-5 shadow-md">
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, idx) => (
                      <TableCell size="small" sx={{ fontWeight: 500 }} key={idx} align="center">
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products?.map((row) => {
                    return (
                      <TableRow className="capitalize" key={row.id}>
                        <TableCell size="small" align="center">
                          {row.id}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.inv_tipo_equipo.descripcion}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.modelo.toLowerCase()}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.serie}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {dateFormatter(row.f_instalacion)}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {dateFormatter(row.f_garantia)}
                        </TableCell>
                        <TableCell size="small" align="center">
                          <div className="flex justify-center gap-5">
                            {row?.inv_tipo_equipo?.inv_repuesto ? (
                              <div>
                                <RepairPartOT
                                  idTeam={row.id}
                                  cantidades={cantidades}
                                  setCantidades={setCantidades}
                                  repuestos={row?.inv_tipo_equipo?.inv_repuesto}
                                />
                              </div>
                            ) : (
                              <Chip color="error" sx={{ mr: 1 }} label="Sin repuestos" />
                            )}
                            <Chip
                              onClick={(e) => handleDeleteProducts(row.id)}
                              disabled={disabledRepairPartDeleteOption[row.id] ? true : false}
                              color="error"
                              variant="outlined"
                              sx={{ textTransform: "none" }}
                              label="Eliminar"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="w-full h-auto grid grid-cols-1 md:grid-cols-3 md:hidden">
            {products?.map((row) => {
              return (
                <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                  <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                    <label className="tracking-wider text-sm font-semibold">ID:</label>
                    <label className="text-sm">{row.id}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Equipo:</label>
                    <label className="text-sm">{capitalizeFirstLetter(row.descripcion)}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Modelo:</label>
                    <label className="text-sm">{capitalizeFirstLetter(row.modelo)}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Serie:</label>
                    <label className="text-sm">{capitalizeFirstLetter(row.serie)}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Fecha de instalación:</label>
                    <label className="text-sm">{dateFormatter(row.f_instalacion)}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Fecha de garantia:</label>
                    <label className="text-sm">{dateFormatter(row.f_garantia)}</label>
                  </div>
                  <div className="flex mt-2 w-full">
                    {row?.inv_tipo_equipo?.inv_repuesto ? (
                      <RepairPartOT
                        idTeam={row.id}
                        cantidades={cantidades}
                        setCantidades={setCantidades}
                        repuestos={row?.inv_tipo_equipo?.inv_repuesto}
                      />
                    ) : (
                      <Chip color="error" sx={{ mr: 1 }} label="Sin repuestos" />
                    )}
                    <Button
                      onClick={(e) => handleDeleteProducts(row.id)}
                      disabled={disabledRepairPartDeleteOption[row.id] ? true : false}
                      color="error"
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{ textTransform: "none", ml: 1 }}
                    >
                      Eliminar
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        {!ot.otFacturacionRepuesto?.length && !ot.ot_repuesto_ot_repuesto_otToot.length ? null : (
          <section>
            <div className=" mb-2 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">REPUESTOS</div>
            <div className="w-full h-auto max-md:hidden">
              <TableContainer sx={{ position: "relative" }} className="mb-5 bg-slate-100 rounded p-5 shadow-md">
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      {columns_rp.map((column, idx) => (
                        <TableCell size="small" sx={{ fontWeight: 500 }} key={idx} align="center">
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ot.ot_repuesto_ot_repuesto_otToot?.map((row) => {
                      return (
                        row.cantidad !== 0 && (
                          <TableRow className="capitalize" key={row.id}>
                            <TableCell size="small" align="center">
                              {row.inv_repuesto.codigo_loginteg || "S/D"}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.inv_repuesto.descripcion}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.inv_repuesto.modelo}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.inv_repuesto.serie}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.inv_repuesto.cantidad}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.cantidad}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.cantidad}
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                    {ot.otFacturacionRepuesto?.map((row) => {
                      return (
                        row.cantidad !== 0 && (
                          <TableRow className="capitalize" key={row.id}>
                            <TableCell size="small" align="center">
                              {row.inv_repuesto.codigo_loginteg || "S/D"}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.inv_repuesto.descripcion}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.inv_repuesto.modelo}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.inv_repuesto.serie}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.inv_repuesto.cantidad}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.cantidad}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.cantidad}
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="w-full h-auto grid grid-cols-1 md:grid-cols-3 md:hidden">
              {ot.ot_repuesto_ot_repuesto_otToot?.map((row) => {
                return (
                  row.cantidad !== 0 && (
                    <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                      <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                        <label className="tracking-wider text-sm font-semibold">Código Loginteg:</label>
                        <label className="text-sm">{row.inv_repuesto.codigo_loginteg}</label>
                      </div>
                      <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                        <label className="tracking-wider text-sm font-semibold">Nombre:</label>
                        <label className="text-sm">{row.inv_repuesto.descripcion}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Modelo:</label>
                        <label className="text-sm">{capitalizeFirstLetter(row.inv_repuesto.modelo)}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Serie:</label>
                        <label className="text-sm">{capitalizeFirstLetter(row.inv_repuesto.serie)}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Cantidad:</label>
                        <label className="text-sm">{row.inv_repuesto.cantidad}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Cantidad Solicitada:</label>
                        <label className="text-sm">{row.cantidad}</label>
                      </div>
                    </div>
                  )
                );
              })}
              {ot.otFacturacionRepuesto?.map((row) => {
                return (
                  row.cantidad !== 0 && (
                    <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                      <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                        <label className="tracking-wider text-sm font-semibold">Código Loginteg:</label>
                        <label className="text-sm">{row.inv_repuesto.codigo_loginteg}</label>
                      </div>
                      <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                        <label className="tracking-wider text-sm font-semibold">Nombre:</label>
                        <label className="text-sm">{row.inv_repuesto.descripcion}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Modelo:</label>
                        <label className="text-sm">{capitalizeFirstLetter(row.inv_repuesto.modelo)}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Serie:</label>
                        <label className="text-sm">{capitalizeFirstLetter(row.inv_repuesto.serie)}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Cantidad:</label>
                        <label className="text-sm">{row.inv_repuesto.cantidad}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Cantidad Solicitada:</label>
                        <label className="text-sm">{row.cantidad}</label>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </section>
        )}
        {!ot.otFacturacionArticulo?.length ? null : (
          <section>
            <div className=" mb-2 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">INSUMOS</div>
            <div className="w-full h-auto max-md:hidden">
              <TableContainer sx={{ position: "relative" }} className="mb-5 bg-slate-100 rounded p-5 shadow-md">
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      {columns_articulo.map((column, idx) => (
                        <TableCell size="small" sx={{ fontWeight: 500 }} key={idx} align="center">
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ot.otFacturacionArticulo?.map((row) => {
                      return (
                        <TableRow className="capitalize" key={row.id}>
                          <TableCell size="small" align="center">
                            {row.inv_articulos.nombre}
                          </TableCell>
                          <TableCell size="small" align="center">
                            {row.inv_articulos.nombre}
                          </TableCell>
                          <TableCell size="small" align="center">
                            {row.inv_articulos.lotes}
                          </TableCell>
                          <TableCell size="small" align="center">
                            {row.inv_articulos.fecha_vencimiento
                              ? dayjs(row.inv_articulos.fecha_vencimiento).format("DD/MM/YYYY")
                              : "S/D"}
                          </TableCell>
                          <TableCell size="small" align="center">
                            {row.inv_articulos.cantidad}
                          </TableCell>
                          <TableCell size="small" align="center">
                            {row.cantidad}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="w-full h-auto grid grid-cols-1 md:grid-cols-3 md:hidden">
              {ot.otFacturacionArticulo?.map((row) => {
                return (
                  <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                    <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                      <label className="tracking-wider text-sm font-semibold">Código Loginteg:</label>
                      <label className="text-sm">{row.inv_articulos.nombre}</label>
                    </div>
                    <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                      <label className="tracking-wider text-sm font-semibold">Nombre:</label>
                      <label className="text-sm">{row.inv_articulos.nombre}</label>
                    </div>
                    <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                      <label className="tracking-wider text-sm font-semibold">Lote:</label>
                      <label className="text-sm">{row.inv_articulos.lotes}</label>
                    </div>
                    <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                      <label className="tracking-wider text-sm font-semibold">Fecha de vencimiento:</label>
                      <label className="text-sm">
                        {row.inv_articulos.fecha_vencimiento
                          ? dayjs(row.inv_articulos.fecha_vencimiento).format("DD/MM/YYYY")
                          : "S/D"}
                      </label>
                    </div>
                    <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                      <label className="tracking-wider text-sm font-semibold">Cantidad:</label>
                      <label className="text-sm">{row.inv_articulos.cantidad}</label>
                    </div>
                    <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                      <label className="tracking-wider text-sm font-semibold">Cantidad Solicitada:</label>
                      <label className="text-sm">{row.cantidad}</label>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        )}
        {!ot.otFacturacionServicio?.length ? null : (
          <section>
            <div className=" mb-2 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">SERVICIOS</div>
            <div className="w-full h-auto max-md:hidden">
              <TableContainer sx={{ position: "relative" }} className="mb-5 bg-slate-100 rounded p-5 shadow-md">
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      {columns_servicio.map((column, idx) => (
                        <TableCell size="small" sx={{ fontWeight: 500 }} key={idx} align="center">
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ot.otFacturacionServicio?.map((row) => {
                      return (
                        <TableRow className="capitalize" key={row.id}>
                          <TableCell size="small" align="center">
                            {row.servicios.nombre}
                          </TableCell>
                          <TableCell size="small" align="center">
                            {row.cantidad}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="w-full h-auto grid grid-cols-1 md:grid-cols-3 md:hidden">
              {ot.otFacturacionServicio?.map((row) => {
                return (
                  <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                    <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                      <label className="tracking-wider text-sm font-semibold">Nombre:</label>
                      <label className="text-sm">{row.servicios.nombre}</label>
                    </div>
                    <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                      <label className="tracking-wider text-sm font-semibold">Cantidad:</label>
                      <label className="text-sm">{row.cantidad}</label>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        )}
        <section className="flex flex-col">
          <div className=" mb-5 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">OBSERVACIONES</div>
          <div>
            <TextField
              size="small"
              fullWidth
              id="outlined-multiline-static"
              multiline
              rows={4}
              placeholder="Observaciones"
              defaultValue={ot?.ot_observacion_ot_observacion_otToot[0]?.descripcion}
              error={Boolean(errors?.observacion)}
              InputProps={{
                endAdornment: (
                  <div className="w-52 text-red-500">{errors?.observacion ? "El campo es requerido" : ""}</div>
                ),
              }}
              {...register("observacion", { required: true })}
            />
          </div>
        </section>
        <div className="mt-5 flex justify-center w-full">
          <Button fullWidth type="submit" variant="contained">
            EDITAR OT
          </Button>
        </div>
      </div>
    </form>
  ) : (
    <div className="w-full h-skeleton-datagrid flex justify-center items-center">
      <FacebookCircularProgress />
    </div>
  );
}
