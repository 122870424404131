import { useState } from "react";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import CustomInformSelect from "../../components/Informes/CustomInformSelect";
import BodegaEquipos from "../../components/Informes/BodegaEquipos";
import InvInsumos from "../../components/Informes/InvInsumos";
import Contactos from "../../components/Informes/Contactos";
import { Elements } from "../Elements";
import EquiposAsignados from "../../components/Informes/EquiposAsignados";
import InvRepuestos from "../../components/Informes/InvRepuestos";
import OtAsignadas from "../../components/Informes/OtAsignadas";
import OtGeneral from "../../components/Informes/OtGeneral";
import RendicionAnual from "../../components/Informes/InformeRendicionAnual";
import RendicionGastos from "../../components/Informes/InformeRendicionGastos";

export default function Informes() {
  const [selectedOption, setSelectOption] = useState();

  const renderInforme = () => {
    switch (selectedOption?.id) {
      case 1:
        return <BodegaEquipos />;
      case 2:
        return <InvInsumos />;
      case 3:
        return <InvRepuestos />;
      case 4:
        return <Elements isInform />;
      case 5:
        return <Contactos />;
      case 6:
        return <EquiposAsignados />;
      case 7:
        return <OtAsignadas />;
      case 8:
        return <OtGeneral />;
      case 9:
        return <RendicionAnual />;
      case 10:
        return <RendicionGastos />;
      default:
        return <></>;
    }
  };

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Informes</h1>
          <BreadCrumb origin={"Informes"} current={"Informes"} />
        </div>
        <div>
          <div className="w-[600px] mr-[20px]">
            <CustomInformSelect selectedOption={selectedOption} setSelectOption={setSelectOption} />
          </div>
        </div>
      </div>
      {renderInforme()}
    </div>
  );
}
