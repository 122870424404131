import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { getOTByClientID } from "../../redux/slices/workOrders";
import { setOpen } from "../../redux/slices/editModal";
import { useDispatch } from "react-redux";
import HistoryModal from "../modal/HistoryModal";
import dateFormatter from "../../utils/dateFormatter";
import dayjs from "dayjs";

const ExecuteOtGrid = ({ columns, rows, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectToExecute = (ot_id) => {
    navigate(`/execute-ot/${ot_id}`);
  };

  const handleHistory = (ot_id) => {
    dispatch(getOTByClientID(ot_id));
    dispatch(setOpen({ open: true, id: ot_id }));
  };

  const formated = rows.map((el) => ({
    ...el,
    cliente: el.cliente_ot_clienteTocliente.nombre,
    f_ot: el?.f_ot ? dayjs(el.f_ot).format("DD/MM/YYYY") : "S/D",
    f_creacion: el?.f_creacion ? dayjs(el.f_creacion).format("DD/MM/YYYY") : "S/D",
    garantia: el.garantia ? "Si" : "No",
    observaciones: el.ot_observacion_ot_observacion_otToot.length ? "Si" : "No",
    acciones: (
      <>
        <Button
          color={el.cerrada ? "primary" : "success"}
          onClick={() => redirectToExecute(el.id)}
          variant={"outlined"}
          size={"small"}
          sx={{ minWidth: "85px" }}
        >
          {el.cerrada ? "Cerrada" : "Ejecutar"}
        </Button>
        <IconButton sx={{ ml: 2 }} id={el.id} onClick={() => handleHistory(el.cliente_ot_clienteTocliente.id)}>
          <HistoryIcon sx={{ pointerEvents: "none" }} />
        </IconButton>
      </>
    ),
  }));

  return (
    <>
      <div className="max-lg:hidden">
        <TableContainer sx={{ position: "relative", boxShadow: 0 }} className="rounded p-5 shadow-md">
          <Table sx={{ minWidth: 650, boxShadow: 0 }} aria-label="caption table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell sx={{ fontWeight: 600 }} key={column.value}>
                    {column.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row) => (
                  <TableRow key={row.id}>
                    {/* <TableCell>{row.id}</TableCell> */}
                    <TableCell>{row.cliente_ot_clienteTocliente.nombre}</TableCell>
                    <TableCell>{dateFormatter(row.f_ot)}</TableCell>
                    <TableCell>{dateFormatter(row.f_creacion)}</TableCell>
                    <TableCell>{row.garantia ? "Si" : "No"}</TableCell>
                    <TableCell>{row.ot_observacion_ot_observacion_otToot.length ? "Si" : "No"}</TableCell>
                    <TableCell>
                      <Button
                        color={row.cerrada ? "primary" : "success"}
                        onClick={() => redirectToExecute(row.id)}
                        variant={"outlined"}
                        size={"small"}
                        sx={{ minWidth: "85px" }}
                      >
                        {row.cerrada ? "Cerrada" : "Ejecutar"}
                      </Button>
                      <IconButton
                        sx={{ ml: 2 }}
                        id={row.id}
                        onClick={() => handleHistory(row.cliente_ot_clienteTocliente.id)}
                      >
                        <HistoryIcon sx={{ pointerEvents: "none" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <HistoryModal />
        </TableContainer>
      </div>
      <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-5  w-full h-full lg:hidden">
        {formated &&
          formated.map((row) => (
            <div className="w-full rounded-lg bg-neutral-100 border-neutral-300 border min-h-20 p-2">
              {columns.map((el) => (
                <div className="mb-1 gap-2 items-start">
                  <div className="font-bold">{el.title}:</div>
                  <div className=" border-b border-neutral-300">{row[el.value]}</div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </>
  );
};

export default ExecuteOtGrid;
