import { useSelector, useDispatch } from "react-redux";
import { getAllClients } from "../redux/slices/clients";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { useEffect } from "react";
import ExecuteOtGrid from "../components/executeOtGrid/ExecuteOtGrid";
import { getOTByTechnicalWorkerId } from "../redux/slices/workOrders";
import FacebookCircularProgress from "../components/FacebookLoading/FacebookLoading";

const selfColumn = [
  { title: "Cliente", value: "cliente" },
  { title: "Fecha de OT", value: "f_ot" },
  { title: "Garantia", value: "garantia" },
  { title: "Fecha creación", value: "f_creacion" },
  { title: "Observaciones", value: "observaciones" },
  { title: "Acciones", value: "acciones" },
];

export default function ExecuteOt() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.clients);
  const { tech_ots, success } = useSelector((state) => state.workOrders);
  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!data.length) {
      dispatch(getAllClients());
    }

    dispatch(getOTByTechnicalWorkerId(currentUser.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.length]);

  const sorted =
    tech_ots && tech_ots.length
      ? [...tech_ots]
          .sort(function (x, y) {
            return y.cerrada === x.cerrada ? 0 : y.cerrada ? -1 : 1;
          })
          .filter((el) => !el.finalizada)
      : [];

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Ejecuta OT</h1>
          <BreadCrumb origin={"Servicio técnico"} current={"Ejecuta OT"} />
        </div>
      </div>
      {sorted?.length ? (
        <div className="p-2 rounded border-container h-full overflow-y-auto calculated-height-execute">
          <ExecuteOtGrid columns={selfColumn} rows={sorted} />
        </div>
      ) : !tech_ots?.filter((el) => !el.finalizada)?.length && success ? (
        <div className="w-full ">
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline font-semibold">No tiene ordenes de trabajo asignadas</span>
          </div>
        </div>
      ) : (
        <div className="w-full h-skeleton-datagrid flex justify-center items-center">
          <FacebookCircularProgress />
        </div>
      )}
    </div>
  );
}
