import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, esES, gridClasses } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { styled, alpha } from "@mui/material/styles";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import exportToExcel from "../../utils/exportToExcel";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { TextField, MenuItem } from "@mui/material";

const ODD_OPACITY = 0.2;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .status-entransito": {
    backgroundColor: "#FFF7B3",
    "&:hover": {
      backgroundColor: "#dfd89d  ",
    },
    "&.Mui-selected": {
      backgroundColor: "#FFF7B3",
      "&:hover": {
        backgroundColor: "#FFF7B3",
      },
    },
  },
  "& .status-nota-credito": {
    backgroundColor: "#ffcccc",
    "&:hover": {
      backgroundColor: "#ff9999",
    },
    "&.Mui-selected": {
      backgroundColor: "#ffcccc",
      "&:hover": {
        backgroundColor: "#ffcccc",
      },
    },
  },
  "& .status-reservado": {
    backgroundColor: "#aee6b1",
    "&:hover": {
      backgroundColor: "#c0fac4",
    },
    "&.Mui-selected": {
      backgroundColor: "#aee6b1",
      "&:hover": {
        backgroundColor: "#c0fac4",
      },
    },
  },
  "& .status-vigente": {
    backgroundColor: "#B3D1FF",
    "&:hover": {
      backgroundColor: "#8ba4ca",
    },
    "&.Mui-selected": {
      backgroundColor: "#B3D1FF",
      "&:hover": {
        backgroundColor: "#B3D1FF",
      },
    },
  },
  "& .status-novigente": {
    backgroundColor: "#D3D3D3 ",
    "&:hover": {
      backgroundColor: "#c0bcbc ",
    },
    "&.Mui-selected": {
      backgroundColor: "#D3D3D3 ",
      "&:hover": {
        backgroundColor: "#D3D3D3 ",
      },
    },
  },
  "& .status-encliente": {
    backgroundColor: "#dafadf ",
    "&:hover": {
      backgroundColor: "#dafadf",
    },
    "&.Mui-selected": {
      backgroundColor: "#dafadf",
      "&:hover": {
        backgroundColor: "#dafadf",
      },
    },
  },
  "& .status-enbodega": {
    backgroundColor: "#FFDAB9  ",
    "&:hover": {
      backgroundColor: "#FFDAB9",
    },
    "&.Mui-selected": {
      backgroundColor: "#FFDAB9",
      "&:hover": {
        backgroundColor: "#FFDAB9",
      },
    },
  },
  "& .status-baja": {
    backgroundColor: "#FFC0CB  ",
    "&:hover": {
      backgroundColor: "#FFC0CB",
    },
    "&.Mui-selected": {
      backgroundColor: "#FFC0CB",
      "&:hover": {
        backgroundColor: "#FFC0CB",
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

export default function DataGridComponent({
  columns,
  rows,
  success,
  dateSort,
  filename = "TablaExportada",
  size = "0px",
  logicalDelete = false,
  selectorValue,
  logicalDeleteSetter = null,
}) {
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="w-full flex justify-between">
        <div className="flex">
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <div
            className="text-[#2B2C84] font-medium flex items-center gap-1 text-[13px] cursor-pointer hover:bg-slate-50 py-1"
            onClick={() => exportToExcel(rows, columns, filename)}
          >
            <SaveAltIcon sx={{ height: "22px", width: "22px" }} /> <span>EXPORTAR A EXCEL</span>
          </div>
        </div>
        {logicalDelete && (
          <div className="w-[150px]">
            <TextField
              fullWidth
              select
              size="small"
              onChange={logicalDeleteSetter}
              value={selectorValue}
              label={"Estado"}
              placeholder=""
              sx={{
                "& .MuiFormHelperText-root": {
                  marginLeft: 0,
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                },
              }}
            >
              <MenuItem dense divider value={0}>
                Activo
              </MenuItem>
              <MenuItem dense divider value={1}>
                Inactivo
              </MenuItem>
            </TextField>
          </div>
        )}
      </GridToolbarContainer>
    );
  }
  return (
    <Box sx={{ height: `calc(100vh - 65px - 32px - 80px - 24px - ${size})` }}>
      <StyledDataGrid
        className="transition-all"
        sx={{ p: 2, overflowX: "scroll" }}
        rows={rows}
        columns={columns}
        slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress }}
        slotProps={{}}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          sorting: {
            sortModel: [{ field: !dateSort ? "id" : "closestMaintenance", sort: "asc" }],
          },
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        getRowClassName={(params) => {
          if (params.row?.numero_nota_credito) {
            if (typeof params.row?.numero_nota_credito === "number") {
              return "status-nota-credito";
            }
          }
          return params.row?.inv_estado?.descripcion
            ? `status-${params.row.inv_estado?.descripcion.replace(/\s/g, "").toLowerCase()}`
            : params.indexRelativeToCurrentPage % 2 === 0
            ? "even"
            : "odd";
        }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        pageSizeOptions={[50, 1]}
        loading={!success ? true : false}
      />
    </Box>
  );
}
