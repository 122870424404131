import { useEffect, useState } from "react";
import { getAllCobranzas } from "../redux/slices/cobranzas";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import useDataGrid from "../hooks/useDataGrid";
import DataGridComponent from "../components/tables/DataGrid";
import { CircularProgress } from "@mui/material";
export default function Facturas() {
  const dispatch = useDispatch();
  const { cobranzas, success } = useSelector((state) => state.cobranzas);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  useEffect(() => {
    setLoading(true);
    dispatch(getAllCobranzas()).then(() => setLoading(false));
  }, []);

  const permitedRenderDataColumn = [
    { title: "ID", value: "id", hide: true },
    { title: "Nro Factura", value: "nro_factura" },
    { title: "Cliente", value: "cliente_facutra" },
    { title: "F. Facturación", value: "f_facturacion" },
    { title: "Nota de Crédito", value: "numero_nota_credito" },
    { title: "Monto", value: "monto_withOut_iva" },
    { title: "Iva", value: "iva" },
    { title: "Total", value: "monto" },
    { title: "Nro OC", value: "nro_oc" },
    { title: "Fecha OC", value: "fecha_oc" },
    { title: "Estado", value: "estado_factura" },
    { title: "Pago Parcial", value: "p_parcial" },
    { title: "Pago Múltiple", value: "p_multiple" },
    { title: "Acciones", value: "acciones_facturas" },
  ];

  const handleDelete = async (params) => {};
  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Marcas", false, setOpen);
  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Facturas Generadas</h1>
          <BreadCrumb origin={"Facturacion"} current={"Facturas Generadas"} />
        </div>
      </div>
      {loading ? (
        <div className="h-[calc(100vh_-_65px_-_32px_-_80px_-_24px)] flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <DataGridComponent columns={columns} rows={cobranzas} success={success} />
      )}
    </div>
  );
}
