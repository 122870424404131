import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import { Button, MenuItem, TextField } from "@mui/material";
import logo from "../../assets/loginteg.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getAllClients, getClientById, resetClientById } from "../../redux/slices/clients";
import { getProductCliente } from "../../redux/slices/invProducts";
import { createWorkOrder, setRedirect, getOtTypes } from "../../redux/slices/workOrders";
import FacebookCircularProgress from "../FacebookLoading/FacebookLoading";
import Chip from "@mui/material/Chip";
import RepairPartOT from "../modal/RepairPartOT";
import { setNotification } from "../../redux/slices/notification";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getAllContactMeans } from "../../redux/slices/contactMeans";
import { validateRUT } from "validar-rut";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import dateFormatter from "../../utils/dateFormatter";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DataPicker from "../dataPicker/DataPicker";
import { DatePicker } from "@mui/x-date-pickers";
import FreeSoloCreateOptionDialog from "../FreeSoloCreateOptionDialog";
import ContactDialog from "../FreeSoloCreateOptionDialog/ContactDialog";

const columns = [
  { title: "ID", value: "id" },
  { title: "Equipo", value: "equipo" },
  { title: "Modelo", value: "modelo" },
  { title: "Nº serie", value: "serie" },
  { title: "Fecha de instalacion", value: "instalacion" },
  { title: "Fecha de garantia", value: "garantia" },
  { title: "Acciones", value: "acciones" },
];

const columns_rp = [
  { title: "Equipo" },
  { title: "Código Loginteg" },
  { title: "Nombre" },
  { title: "N° Modelo" },
  { title: "Serie" },
  { title: "Cantidad disponible" },
  { title: "Cantidad deseada" },
];

export default function FormOt(schema) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { client, data: clientData } = useSelector((state) => state.clients);
  const { clienteProduct } = useSelector((state) => state.invProducts);
  const { data: contactMeans } = useSelector((state) => state.contactMeans);
  const data = useSelector((state) => state.auth.currentUser);
  const { redirect, ot_types } = useSelector((state) => state.workOrders);
  const [products, setProducts] = useState([]);
  const [updateValueCost, setUpdateValueCost] = useState(true);
  const [selectMessage, setSelectMessage] = useState({
    bool: false,
    message: "",
  });
  const [selectProduct, setSelectProduct] = useState("");
  const [cantidades, setCantidades] = useState({});
  const [disabledRepairPartDeleteOption, setDisabledRepairPartDeleteOption] = useState([]);
  const [isRutValidationRequired, setIsRutValidationRequired] = useState(true);
  const [costError, setCostError] = useState(false);
  const navigate = useNavigate();
  const [contactSelected, setContactSelected] = useState(null);
  const [autocompleteErrors, setAutocompleteErrors] = useState({
    resp_cliente: false,
  });

  useEffect(() => {
    setValue("resp_correo", contactSelected?.correo1 || "");
    setValue("rut_cliente", contactSelected?.rut || "");
  }, [contactSelected]);

  const validarRut = (value, isRutValidationRequired) => {
    if (isRutValidationRequired) {
      return validateRUT(value);
    } else {
      return true;
    }
  };

  useEffect(() => {
    dispatch(getClientById(id));
    dispatch(getProductCliente(id));
    dispatch(getOtTypes());
    dispatch(getAllContactMeans());
    dispatch(getAllClients());

    if (redirect === true) return navigate("/create-ot");

    return () => {
      dispatch(resetClientById());
      dispatch(setRedirect(false));
    };
  }, [dispatch, id, redirect]);

  useEffect(() => {
    if (products.length) {
      setSelectMessage({
        bool: false,
        message: "",
      });
    }
  }, [products]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  let telefono1 = "";
  let telefono2 = "";
  let contacto = "PRINCIPAL";

  const handleProduct = () => {
    if (selectProduct === "") return;
    const find = products?.find((prod) => prod.id === selectProduct);
    if (find) return;

    const newProduct = clienteProduct.find((prod) => prod.id === selectProduct);

    if (newProduct?.inv_tipo_equipo) {
      if (!products.length) {
        const repuestos = {};

        newProduct.inv_tipo_equipo.inv_repuesto.forEach((repuesto) => {
          repuestos[repuesto.id] = {
            cantidad_total: repuesto.cantidad,
            [newProduct.id]: 0,
          };
        });

        setCantidades({ ...cantidades, ...repuestos });
      } else {
        newProduct.inv_tipo_equipo.inv_repuesto.forEach((repuesto) => {
          if (cantidades.hasOwnProperty(repuesto.id)) {
            cantidades[repuesto.id] = {
              ...cantidades[repuesto.id],
              [newProduct.id]: 0,
            };
          } else {
            cantidades[repuesto.id] = {
              cantidad_total: repuesto.cantidad,
              [newProduct.id]: 0,
            };
          }
        });

        setCantidades({ ...cantidades });
      }
    }

    setProducts([...products, newProduct]);
  };

  const handleDeleteProducts = (id) => {
    for (const key in cantidades) {
      if (cantidades[key][id] > 0) {
        dispatch(
          setNotification({
            open: true,
            message: "Este equipo contiene repuestos asignados",
            status: "warning",
          }),
        );
        return;
      }
    }
    setProducts((prevState) => prevState.filter((prod) => prod.id !== id));
  };

  const clientWatcher = watch("resp_cliente");

  useEffect(() => {
    const clientFillData = clientData.find((el) => el.id === clientWatcher);
    if (clientFillData) {
      setValue("rut_cliente", clientFillData.rut);
      setValue("resp_correo", clientFillData.mail_contacto);
    }
  }, [clientWatcher]);

  Object.keys(client).length > 0 &&
    client.contacto_contacto_clienteTocliente.forEach((contacto) => {
      if (contacto.principal === true) {
        telefono1 = contacto.telefono1;
        telefono2 = contacto.telefono2;
      }
    });

  const submit = (values) => {
    if (costError) return;
    if (!products.length) {
      setSelectMessage({
        bool: true,
        message: "El campo es requerido",
      });
      return;
    }
    values.cliente = id;
    let equipos = [];
    products.forEach((prod) => equipos.push(prod.id));
    values.equipos = equipos;
    values.garantia = values.garantia === "true" ? true : false;
    values.responsable = data.id;
    values.resp_cliente = parseInt(contactSelected.id);
    if (!values.f_ejecucion) {
      values.f_ejecucion = dayjs();
    }
    if (!values.horario_atencion) {
      values.horario_atencion = dayjs();
    }
    dispatch(createWorkOrder(values, cantidades));
  };

  const activesRp = products
    ?.map((row) => {
      const idTeam = row.id;
      const productRepairParts = row?.inv_tipo_equipo?.inv_repuesto;

      const curr = productRepairParts.map((rp) => {
        return {
          ...rp,
          active_quantity: cantidades[rp.id][idTeam],
          equipmentName: `${row.descripcion} ${row.modelo} ${row.serie}`,
        };
      });

      return curr.filter((crp) => crp.active_quantity > 0);
    })
    .flat();

  const costoWatcher = watch("costo");

  useEffect(() => {
    if (!/^[0-9]*$/.test(costoWatcher)) {
      setCostError(true);
    } else {
      setCostError(false);
    }
  }, [costoWatcher]);

  useEffect(() => {
    setValue("costo", 0);

    if (products.length) {
      let acc = 0;

      products.forEach((product) => {
        acc += product.valueCost;
      });

      activesRp.forEach((el) => {
        acc += el.active_quantity * el.valueCost;
      });

      setValue("costo", acc);
    }
  }, [products, activesRp.length, updateValueCost]);

  return client.id ? (
    <form className="px-1 sm:px-10" onSubmit={handleSubmit(submit)}>
      <section className="flex flex-col sm:flex-row items-center border-b-2  border-black h-[auto] sm:h-[auto] mb-10">
        <div className="w-[20%] hidden sm:block">
          <img src={logo} alt="logo-loginteg" className="sm:w-[200px] sm:h-[150px]" />
        </div>
        <div className="sm:w-[80%] flex flex-col items-center">
          <h1 className="font-bold text-xl">LOGISTICA INTEGRAL SPA</h1>
          <p className="font-semibold text-xl">SERVICIO TÉCNICO AUTORIZADO</p>
          <p className="font-semibold text-xl mb-1">INSTALACIONES - SALUD</p>
          <div className="flex">
            <span className="text-sm font-bold">Sede Comercial: </span>
            <span className="text-sm">Los canteros 8781, La Reina, Santiago de Chile</span>
          </div>
          <div className="flex">
            <span className="text-sm font-bold">E-mail: </span>
            <span className="text-sm">instaladores@loginteg.cl ; pcaro@loginteg.cl</span>
          </div>
          <div className="flex">
            <span className="text-sm font-bold">Telefono Celular: </span>
            <span className="text-sm">+56931454472</span>
          </div>
        </div>
      </section>
      <div>
        <section className="w-full flex flex-col h-auto">
          <div className="mb-8 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">INFORMACIÓN DEL CLIENTE</div>
          <div className="flex flex-col sm:flex-row">
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                nombre:
              </label>
              <TextField
                fullWidth
                disabled
                InputProps={{
                  endAdornment: <div className="hidden sm:block">Desabilitado</div>,
                }}
                id="filled-disabled"
                value={client?.nombre}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                rut:
              </label>
              <TextField
                fullWidth
                disabled
                InputProps={{
                  endAdornment: <div className="hidden sm:block">Desabilitado</div>,
                }}
                id="filled-disabled"
                value={client?.rut}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                comuna:
              </label>
              <TextField
                fullWidth
                disabled
                InputProps={{
                  endAdornment: <div className="hidden sm:block">Desabilitado</div>,
                }}
                id="filled-disabled"
                value={client?.comuna}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                direccion:
              </label>
              <TextField
                disabled
                InputProps={{
                  endAdornment: <div className="hidden sm:block">Desabilitado</div>,
                }}
                fullWidth
                id="filled-disabled"
                value={client?.direccion}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                contacto:
              </label>
              <TextField
                fullWidth
                disabled
                InputProps={{
                  endAdornment: <div>Desabilitado</div>,
                }}
                id="filled-disabled"
                value={contacto}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                telefono:{" "}
              </label>
              <TextField
                fullWidth
                disabled
                InputProps={{
                  endAdornment: <div>Desabilitado</div>,
                }}
                id="filled-disabled"
                value={telefono2}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
          </div>
          <div className="flex"></div>
          <div className="flex flex-col sm:flex-row">
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0 ">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                garantia:{" "}
              </label>
              <TextField
                fullWidth
                id="filled-disabled"
                select
                variant="outlined"
                required
                defaultValue={"true"}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("garantia")}
              >
                <MenuItem key={1} value={"true"}>
                  EQUIPO CON GARANTIA
                </MenuItem>
                <MenuItem key={2} value={"false"}>
                  EQUIPO SIN GARANTIA
                </MenuItem>
              </TextField>
            </div>

            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                celular:{" "}
              </label>
              <TextField
                disabled
                InputProps={{
                  endAdornment: <div>Desabilitado</div>,
                }}
                fullWidth
                id="filled-disabled"
                value={telefono1}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                horario de atencion:{" "}
              </label>
              <Controller
                id="horario_atencion"
                control={control}
                name="horario_atencion"
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DateTimePicker
                      onChange={onChange}
                      value={value || dayjs()}
                      format="DD/MM/YYYY HH:mm"
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0 ">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                email:{" "}
              </label>
              <TextField
                fullWidth
                disabled
                InputProps={{
                  endAdornment: <div>Desabilitado</div>,
                }}
                id="filled-disabled"
                value={client?.mail_contacto}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  backgroundColor: "#F1F5F9",
                }}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0 ">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                cliente resp:{" "}
              </label>
              {/* <TextField
                fullWidth
                id="filled-disabled"
                defaultValue={""}
                select
                variant="outlined"
                required
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  },
                }}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("resp_cliente")}
              >
                {clientData
                  ?.filter((el) => el.id !== client.id)
                  .map((contactMean) => (
                    <MenuItem key={contactMean.id} value={contactMean.id}>
                      {contactMean.nombre}
                    </MenuItem>
                  ))}
              </TextField> */}{" "}
              <div className="w-[100%]">
                <FreeSoloCreateOptionDialog
                  name="resp_cliente"
                  label={""}
                  Form={ContactDialog}
                  renderOption="nombre"
                  size="large"
                  options={client.contacto_contacto_clienteTocliente?.filter((el) => el.estado === true)}
                  setValue={setContactSelected}
                  value={contactSelected}
                  defaultValue={client}
                  error={autocompleteErrors.charge}
                  onChange={(newValue, toggleOpen, setDialogValue) => {
                    if (typeof newValue === "string") {
                      setTimeout(() => {
                        toggleOpen(true);
                        setDialogValue({
                          nombre: newValue,
                        });
                      });
                    } else if (newValue && newValue.inputValue) {
                      toggleOpen(true);
                      setDialogValue({
                        nombre: newValue.inputValue,
                      });
                    } else {
                      setContactSelected(newValue);
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                email resp:
              </label>
              <TextField
                fullWidth
                required
                id="filled-disabled"
                defaultValue={""}
                error={Boolean(errors?.resp_correo)}
                InputProps={{
                  endAdornment: <div className="w-32 text-red-500">{errors?.resp_correo ? "Email invalido" : ""}</div>,
                }}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("resp_correo", {
                  pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                })}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                Rut cliente:
              </label>
              <TextField
                fullWidth
                id="filled-disabled"
                defaultValue={""}
                variant="outlined"
                // disabled
                label={errors.rut_cliente?.type === "validarRut" ? "El RUT es invalido" : ""}
                // required
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("rut_cliente", {
                  // required: true,
                  validate: {
                    validarRut: (val) => {
                      return validarRut(val, isRutValidationRequired);
                    },
                  },
                })}
              />
            </div>
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0 ">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                Medio de Contacto:
              </label>
              <TextField
                fullWidth
                id="filled-disabled"
                defaultValue={""}
                select
                variant="outlined"
                required
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("medio_contacto")}
              >
                {contactMeans?.map((contactMean) => (
                  <MenuItem key={contactMean.id} value={contactMean.id}>
                    {contactMean.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0 ">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                Tipo:{" "}
              </label>
              <TextField
                fullWidth
                id="filled-disabled"
                defaultValue={""}
                select
                variant="outlined"
                required
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("tipo_ot")}
              >
                {ot_types?.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0 relative">
              <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
                costo estimado:{" "}
              </label>
              <TextField
                fullWidth
                id="filled-disabled"
                defaultValue={""}
                variant="outlined"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                }}
                {...register("costo")}
              />
              {costError && <div className="absolute text-red-500 text-sm right-12">El campo es numerico</div>}
            </div>
          </div>
          <div className="w-[100%] sm:w-[50%] flex flex-col sm:flex-row items-center sm:pr-8 mb-1 gap-1 mt-3 sm:mt-0">
            <label className="pr-5 w-full min-w-[150px] sm:w-[150px] capitalize bg-slate-300 h-14 text-center flex items-center justify-start pl-5 rounded mr-1">
              Fecha de Ejecución{" "}
            </label>
            <Controller
              id="f_ejecucion"
              control={control}
              name="f_ejecucion"
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                  <DatePicker
                    onChange={onChange}
                    value={value || dayjs()}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              )}
            />
          </div>
          <div className="flex flex-col sm:flex-row"></div>
          <div className="flex flex-col sm:flex-row"></div>
        </section>
        <section className="flex flex-col">
          <div className="mt-8 mb-8 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">EQUIPOS</div>
          <div className="flex flex-col sm:flex-row mb-4 gap-2 sm:gap-0">
            <TextField
              className="w-full sm:w-[40%] p-0 sm:pr-[20px]"
              select
              label="Seleccionar Equipo"
              onChange={(e) => setSelectProduct(e.target.value)}
              defaultValue=""
              error={selectMessage.bool}
              helperText={selectMessage.message}
            >
              {clienteProduct &&
                clienteProduct?.map((prod, index) => (
                  <MenuItem disabled={products?.find((el) => el.id === prod.id)} key={index} value={prod.id}>
                    {prod.inv_tipo_equipo.descripcion + " - " + prod.modelo + " - " + prod.serie}
                  </MenuItem>
                ))}
            </TextField>
            <Button variant="contained" onClick={(e) => handleProduct(e)}>
              Añadir
            </Button>
          </div>
          <div className="max-md:hidden">
            <TableContainer sx={{ position: "relative" }} className="mb-5 bg-slate-100 rounded p-5 shadow-md">
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell sx={{ fontWeight: 500 }} key={column.value} align="center">
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products?.map((row) => {
                    return (
                      <TableRow className="capitalize" key={row.id}>
                        <TableCell align="center">{row.id}</TableCell>
                        <TableCell align="center">{row.inv_tipo_equipo.descripcion}</TableCell>
                        <TableCell align="center">{row.modelo.toLowerCase()}</TableCell>
                        <TableCell align="center">{row.serie}</TableCell>
                        <TableCell align="center">{dateFormatter(row.f_instalacion)}</TableCell>
                        <TableCell align="center">{dateFormatter(row.f_garantia)}</TableCell>
                        <TableCell align="center">
                          <div className="flex justify-center gap-5">
                            {row?.inv_tipo_equipo?.inv_repuesto ? (
                              <div>
                                <RepairPartOT
                                  idTeam={row.id}
                                  cantidades={cantidades}
                                  setCantidades={setCantidades}
                                  repuestos={row?.inv_tipo_equipo?.inv_repuesto}
                                  setUpdateValueCost={setUpdateValueCost}
                                />
                              </div>
                            ) : (
                              <Chip color="error" sx={{ mr: 1 }} label="Sin repuestos" />
                            )}
                            <Chip
                              onClick={(e) => handleDeleteProducts(row.id)}
                              disabled={disabledRepairPartDeleteOption[row.id] ? true : false}
                              color="error"
                              variant="outlined"
                              sx={{ textTransform: "none" }}
                              label="Eliminar"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="w-full h-auto grid grid-cols-1 md:grid-cols-3 md:hidden">
            {products?.map((row) => {
              return (
                <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                  <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                    <label className="tracking-wider text-sm font-semibold">ID:</label>
                    <label className="text-sm">{row.id}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Equipo:</label>
                    <label className="text-sm">{row.descripcion}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Modelo:</label>
                    <label className="text-sm">{row.modelo}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Serie:</label>
                    <label className="text-sm">{row.serie}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Fecha de instalación:</label>
                    <label className="text-sm">{dateFormatter(row.f_instalacion)}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Fecha de garantia:</label>
                    <label className="text-sm">{dateFormatter(row.f_garantia)}</label>
                  </div>
                  <div className="flex mt-2 w-full">
                    {row?.inv_tipo_equipo?.inv_repuesto ? (
                      <RepairPartOT
                        idTeam={row.id}
                        cantidades={cantidades}
                        setCantidades={setCantidades}
                        repuestos={row?.inv_tipo_equipo?.inv_repuesto}
                      />
                    ) : (
                      <Chip color="error" sx={{ mr: 1 }} label="Sin repuestos" />
                    )}
                    <Button
                      onClick={(e) => handleDeleteProducts(row.id)}
                      disabled={disabledRepairPartDeleteOption[row.id] ? true : false}
                      color="error"
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{ textTransform: "none", ml: 1 }}
                    >
                      Eliminar
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        {!activesRp.length ? null : (
          <section>
            <div className=" mb-2 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">REPUESTOS</div>
            <div className="w-full h-auto max-md:hidden">
              <TableContainer sx={{ position: "relative" }} className="mb-5 bg-slate-100 rounded p-5 shadow-md">
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      {columns_rp.map((column, idx) => (
                        <TableCell size="small" sx={{ fontWeight: 500 }} key={idx} align="center">
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activesRp?.map((row) => {
                      return (
                        row.cantidad !== 0 && (
                          <TableRow className="capitalize" key={row.id}>
                            <TableCell size="small" align="center">
                              {row.equipmentName || "S/D"}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.codigo_loginteg || "S/D"}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.descripcion || "S/D"}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.modelo || "S/D"}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.serie}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.cantidad}
                            </TableCell>
                            <TableCell size="small" align="center">
                              {row.active_quantity}
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="w-full h-auto grid grid-cols-1 md:grid-cols-3 md:hidden">
              {activesRp?.map((row) => {
                return (
                  row.cantidad !== 0 && (
                    <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                      <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                        <label className="tracking-wider text-sm font-semibold">Equipo</label>
                        <label className="text-sm">{row.equipmentName || "S/D"}</label>
                      </div>
                      <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                        <label className="tracking-wider text-sm font-semibold">Código Loginteg:</label>
                        <label className="text-sm">{row.codigo_loginteg || "S/D"}</label>
                      </div>
                      <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                        <label className="tracking-wider text-sm font-semibold">Nombre:</label>
                        <label className="text-sm">{row.descripcion || "S/D"}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Modelo:</label>
                        <label className="text-sm">{row.modelo || "S/D"}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Serie:</label>
                        <label className="text-sm">{row.serie || "S/D"}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Cantidad:</label>
                        <label className="text-sm">{row.cantidad || "S/D"}</label>
                      </div>
                      <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                        <label className="tracking-wider text-sm font-semibold">Cantidad Solicitada:</label>
                        <label className="text-sm">{row.active_quantity}</label>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </section>
        )}
        <section className="flex flex-col">
          <div className="mt-8 mb-8 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">OBSERVACIONES</div>
          <div>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              multiline
              rows={4}
              placeholder="Observaciones"
              error={Boolean(errors?.resp_correo)}
              InputProps={{
                endAdornment: (
                  <div className="w-52 text-red-500">{errors?.observacion ? "El campo es requerido" : ""}</div>
                ),
              }}
              {...register("observacion", { required: true })}
            />
          </div>
        </section>
        <div className="mt-[50px] flex justify-center">
          <Button type="submit" variant="contained" sx={{ width: "150px" }}>
            CREAR OT
          </Button>
        </div>
      </div>
    </form>
  ) : (
    <div className="w-full h-skeleton-datagrid flex justify-center items-center">
      <FacebookCircularProgress />
    </div>
  );
}
